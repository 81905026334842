<ngx-ui-loader bgsColor="#DBB03B" fgsColor="#DBB03B" pbColor="#DBB03B" logoUrl="/assets/images/ic_hutano_logo.png" fgsType="three-bounce" logoSize="80" gap="5" >
</ngx-ui-loader>
<router-outlet></router-outlet>


<div bsModal #childModal="bs-modal" class="modal fade uploadImg  send-req_popup" tabindex="-1" role="dialog"
  aria-labelledby="dialog-child-name">
  <div class="modal-dialog ">
    <div class="modal-content">

      <div class="modal-header border-0">
        <button type="button" class="close-pop" aria-label="Close" (click)="hideChildModal()">
          <img src="assets/images/close_gray.svg" alt="" style="width: 3rem;" class="cursor">
        </button>
      </div>



      <div class="popupImg_select">
        <div class="pay_complete">
          <h2>Your session is about to expire. You will be logged out in {{countdown}} seconds.</h2>

          <div class="end_tretmentBtn">
            <button id="tretOneBtn" type="button" (click)="logout()" class="btn btn-danger">Logout</button>
            <button id="tretTwoBtn" type="button" (click)="stay()" class="btn btn-success">Continue Session</button>


          </div>
        </div>
      </div>



    </div>
  </div>
</div>
