<!--------------------------------start of header------------------------------------------------>
<!-- header part start -->
<header [ngClass]="routeUrl === '/' ? '' : 'bg-change'">
  <div class="container header-width">
    <nav class="navbar navbar-expand-sm">
      <a
        id="logo-image"
        class="navbar-brand"
        [routerLink]="['/provider/dashboard']"
      >
        <img src="assets/images/new_logo.png" alt="" class="img-fluid" />
      </a>
      <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span id="toggle_bar"> <i class="fa fa-bars" aria-hidden="true"></i></span>
      </button> -->

      <!-- <div class="collapse navbar-collapse browser justify-content-end" id="navbarSupportedContent"> -->

      <form class="form-inline header_btn shape_dropdown ml-auto">
        <div></div>
        <p
          *ngIf="registrationComplete"
          class="iconly_bold cursor mr-1"
          [routerLink]="['/provider/activity']"
        >
          <img src="assets/images/Iconly-Bold-Notification.svg" alt="" />
          <span
            *ngIf="this.notificationCount > 0 || errorList.length > 0"
            class="belliconNumber"
            ><i class="fa fa-circle" aria-hidden="true"></i>
            <small>{{ notificationCount }}</small>
          </span>
        </p>

        <!-- <h3 *ngIf="registrationComplete" class="loaction_txt address-sidecontent d-flex" style="align-items: end;">
            <img src="assets/images/location.svg" alt="" class="img-fluid" style="width: 2.5rem;">
            <span class="d-flex mr-3" id="las-vegash" data-toggle="modal" data-target="#exampleModal8">
              <p class="addres-Setwidth"> {{address?.address}}</p> &nbsp; . &nbsp;<strong class="w-mil">Within
                {{address?.radius || 100}} miles <i class="fa fa-angle-down" aria-hidden="true"></i></strong>
            </span>
          </h3> -->
        <ng-template [ngIf]="isAuthenticated">
          <h3 class="loaction_txt position-relative">
            <div class="dflex_header">
              <div
                class="header_bgImg"
                [style.backgroundImage]="
                  userImage
                    ? 'url(' + environment.imageBaseUrl + userImage + ')'
                    : 'url(assets/images/user.png);'
                "
              >
                <span
                  class="img-circle_online"
                  [class.active]="isOnline"
                ></span>
              </div>

              <span id="customdropdown">
                {{ userName }}<img src="assets/images/arrow.svg" alt="" />
              </span>
            </div>
            <span
              id="providerReadyModal"
              data-toggle="modal"
              data-target="#providerExampleModal60"
            >
            </span>
            <span
              id="providerJoinModal"
              data-toggle="modal"
              data-target="#ProviderExampleModal50"
            >
            </span>

            <div class="dropdown-menu newmenu-dropdown">
              <a
                class="dropdown-item d-flex align-items-center justify-content-between"
                style="cursor: pointer"
              >
                <span *ngIf="isOnline == true"> Online </span>
                <span *ngIf="isOnline == false"> Offline </span>
                <label class="switch-online">
                  <input
                    id="checkValue"
                    (click)="checkProviderOnDemandAppointments()"
                    type="checkbox"
                  />
                  <span class="slider-online round-online"></span>
                </label>
              </a>
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                style="cursor: pointer"
                [routerLink]="['/provider/dashboard']"
                >Dashboard</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                style="cursor: pointer"
                (click)="logout()"
                >Logout</a
              >
            </div>
          </h3>
        </ng-template>
        <ng-template [ngIf]="!isAuthenticated">
          <button (click)="goToLogin()" class="login_btn" type="submit">
            Login
          </button>
          <button
            (click)="navigateTo('/authentication/user/register')"
            class="signup_btn"
            type="submit"
          >
            Signup
          </button>
        </ng-template>
      </form>

      <!-- </div> -->
    </nav>
  </div>
</header>

<div [class.hide]="hideModel" class="notifiaction_popup">
  <span
    ><img
      src="assets/images/close_gray.svg"
      (click)="hideNotificationModel()"
      alt=""
  /></span>
  <div class="border_loding">
    <!-- <div *ngFor="let appointmentDetail of appointmentList"> -->
    <div
      class="app-boxModal"
      style="cursor: pointer"
      (click)="redirectToRequestDetail(appointmentDetail?.appointmentId)"
    >
      <div
        class="loadpop-img"
        [style.backgroundImage]="
          appointmentDetail?.avatar
            ? 'url(' +
              environment?.imageBaseUrl +
              appointmentDetail?.avatar +
              ')'
            : 'url(assets/images/user_img.svg)'
        "
      ></div>
      <div class="loadpop-txt">
        <h2>You have recived an appointment!</h2>
        <h3>{{ appointmentDetail?.fullName }}</h3>
        <p>{{ appointmentDetail?.appointmentTime }}</p>
      </div>
    </div>
    <div class="dropdown-divider"></div>
    <!-- </div> -->

    <h3 (click)="navigateTo('/provider/activity')" class="viewAll-txt">
      View All
    </h3>
  </div>
</div>

<!-- header part End -->
<!--End of header-->

<div
  class="modal fade uploadImg send-req_popup"
  data-backdrop="false"
  data-keyboard="false"
  tabindex="-1"
  id="ProviderExampleModal50"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          type="button"
          data-dismiss="modal"
          class="close-pop"
          aria-label="Close"
        >
          <img
            src="assets/images/close_gray.svg"
            alt=""
            style="width: 3rem"
            class="cursor"
          />
        </button>
      </div>
      <div class="popupImg_select">
        <div class="pay_complete">
          <h1>Rejoin call</h1>
          <p class="text-center">{{ rejoinMessage }}</p>
          <div class="end_tretmentBtn">
            <button id="tretOneBtn" data-dismiss="modal">Cancel</button>
            <button id="tretTwoBtn" (click)="rejoinCall()" data-dismiss="modal">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade uploadImg send-req_popup"
  data-backdrop="false"
  data-keyboard="false"
  tabindex="-1"
  id="providerExampleModal60"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          type="button"
          class="close-pop"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img
            src="assets/images/close_gray.svg"
            alt=""
            style="width: 3rem"
            class="cursor"
          />
        </button>
      </div>

      <div class="popupImg_select">
        <div class="pay_complete">
          <h1>Patient ready</h1>
          <p class="text-center">{{ providerReady }}</p>

          <div class="end_tretmentBtn">
            <button id="tretOneBtn" data-dismiss="modal">Cancel</button>
            <button
              id="tretTwoBtn"
              (click)="goToAppointmentList()"
              data-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /////////////////////////////////////////////Block Appointment notification error////////////// -->
<button
  style="display: none"
  id="bulkAppointmentErrorModal"
  type="button"
  class="btn btn-primary"
  data-toggle="modal"
  data-target="#exampleModa43"
  data-backdrop="static"
  data-keyboard="false"
>
  Cancel Bulk Appointment 2
</button>

<div class="modal fade insuraceNew" id="exampleModa43">
  <div class="modal-dialog modal-dialog-centered" style="max-width: 37rem">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          (click)="closeCancelAppointmentModal()"
          id="dismissModal"
          type="button"
          class="close-pop"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img
            src="assets/images/close_gray.svg"
            alt=""
            style="width: 3rem"
            class="cursor"
          />
        </button>
      </div>

      <section class="sec_Office_visit mt-0 mb-0">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="ratdoctor-Hd border-0" style="box-shadow: none">
                <div class="Upcoming-Videotxt bulkAppoinment">
                  <h6>
                    <img
                      src="assets/images/builk.svg"
                      alt=""
                      class="mx-auto d-block"
                    />
                  </h6>

                  <h4 class="another-patient pb-0">Cancel Bulk Appointment</h4>
                  <p class="text-center">
                    Looks like you have
                    {{ this.appointmnetIds?.length }} appointments remaining for
                    the day. Please cancel these appointments before proceeding
                    further.
                  </p>
                </div>

                <div class="meeting_now">
                  <button
                    (click)="providerCancelBulkAppointment()"
                    data-dismiss="modal"
                  >
                    Cancel all Appointments
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
