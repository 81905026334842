<header class="top-header">
  <div class="container-fluid">
    <div class="col-md-12 p-0">
      <div class="row">
        <div class="col-sm-6">
          <div class="top-search d-flex main-slide main-tog">
            <div class="logo-text">
              <img src="assets/images/new_logo.png" alt="" />
            </div>
            <div class="toggle-top">
              <div id="toggle">
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="d-flex user-top">

            <div class="dropdown show">
              <a class="dropdown-toggle" role="button" data-toggle="dropdown">
                {{ userEmail }} &nbsp;
                <i class="fas fa-angle-down" aria-hidden="true"></i>
              </a>

              <div class="dropdown-menu dropdown-menu-right">
                <button class="dropdown-item" type="button" (click)="logout()">
                  Logout
                </button>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header section end -->

<ng-content select="router-outlet"></ng-content>