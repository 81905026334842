<div class="toast-wrap" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="logo">
        <img src="assets/images/favlogo.png" class="img-fluid" width="30px">
    </div>
    <div class="text">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" [class]="options.messageClass" [innerHTML]="message">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alert" [class]="options.messageClass" [attr.aria-label]="message">
            {{ message }}
        </div>
        <div>
            <!-- <a *ngIf="!options.closeButton" class="btn btn-pink btn-sm" (click)="action($event)">{{ undoString }}</a> -->
            <a *ngIf="options.closeButton" (click)="remove()" class="btn text- btn-sm">close</a>
        </div>
    </div>
</div>
<div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>