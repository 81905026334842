import { Router } from "@angular/router";
import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { AuthenticationService } from "../authentication/authentication.service";

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  public includeUrls = ["/doctor-details", "/book-appointment/"];
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      // 'Content-Type': 'application/json',
      // 'Accept': 'application/json'
    };

    const token = this.authenticationService.getToken();
    const registerToken = JSON.parse(localStorage.getItem("organizationUser"))
      ?.organization?.tokens[0].token;
    const tempUser = this.authenticationService.getTempUser();
    const tempPatient = JSON.parse(localStorage.getItem("tempPatient"));
    // console.log("🚀 ~ file: http.token.interceptor.ts ~ line 19 ~ HttpTokenInterceptor ~ intercept ~ tempUser", this.router.url)
    if (
      tempUser &&
      (this.router.url.indexOf("/doctor-details") !== -1 ||
        this.router.url.indexOf("/book-appointment") !== -1)
    ) {
      headersConfig["x-temp-user"] = `${tempUser["_id"]}`;
    }

    if (
      tempPatient &&
      (this.router.url.indexOf("/doctor-details") !== -1 ||
        this.router.url.indexOf("/book-appointment") !== -1)
    ) {
      headersConfig["x-temp-user"] = `${tempPatient["_id"]}`;
    }
    if (token) {
      headersConfig["Authorization"] = `Bearer ${token}`;
    }

    if (registerToken) {
      headersConfig["Authorization"] = `Bearer ${registerToken}`;
    }
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
