import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { PreloadModulesStrategy } from "./core/strategies/preload-modules.strategy";

const routes: Routes = [
  {
    path: "authentication",
    loadChildren: () =>
      import("./modules/auth/auth.module").then((mod) => mod.AuthModule),
  },
  {
    path: "administrator",
    loadChildren: () =>
      import("./modules/admin/admin.module").then((mod) => mod.AdminModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./modules/users/users.module").then((mod) => mod.UsersModule),
  },
  {
    path: "provider",
    loadChildren: () =>
      import("./modules/providers/providers.module").then(
        (mod) => mod.ProvidersModule
      ),
  },
  {
    path: "corporate",
    loadChildren: () =>
      import("./modules/corporate/corporate.module").then(
        (mod) => mod.CorporateModule
      ),
  },
  {
    path: "organization",
    loadChildren: () =>
      import("./modules/organization/organization.module").then(
        (mod) => mod.OrganizationModule
      ),
  },
  { path: "**", redirectTo: "" },
];
// { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top' },
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
