import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MessagingService } from './core/services/messaging.service';
import { SocketService } from './core/services/Socket-io-client/socket.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from './core/services';
import { AuthenticationService } from './core/authentication/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ThemeVariables, ThemeRef, lyl, StyleRenderer } from '@alyle/ui';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AnalyticsSharedService } from './core/services/analytics.service';


const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
  const __ = ref.selectorsOf(STYLES);
  return {
    $global: lyl`{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}
      }
    }`,
    root: lyl`{
      display: block
    }`
  };
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [StyleRenderer]
})
export class AppComponent implements OnInit, OnDestroy {
  readonly classes = this.sRenderer.renderSheet(STYLES, true);
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  public timerTitle = 'angular-idle-timeout';
  public countdown = 0;
  public message;
  public title = 'Hutano'
  public toNavigate = '';
  constructor(readonly sRenderer: StyleRenderer,
    private socketService: SocketService,
    private messagingService: MessagingService,
    private analyticsService: AnalyticsSharedService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute) {

  }
  ngOnDestroy(): void {
    this.idle.stop()
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(path => {
      this.toNavigate = path.toNavigate;
    });
    const userId = 'user001';
    this.messagingService.requestPermission(userId)
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    let isLogin = this.authenticationService.isLogin();
    if (isLogin) {
      this.checkIdle();
    }
  }
  checkIdle() {
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(1200);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(600);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.childModal.hide();
      this.reset();
    });
    this.idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // console.log(this.idleState);
      this.authenticationService.logout();
      // window.location.reload();
      this.router.navigate(['/']);
    });
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      this.childModal.show();
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.countdown = countdown
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });
    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
    let userLoggedIn = this.authenticationService.isLogin();
    if (userLoggedIn) {
      this.idle.watch()
      this.timedOut = false;
    } else {
      this.idle.stop();
    }
  }
  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }
  hideChildModal(): void {
    this.childModal.hide();
  }
  stay() {
    this.childModal.hide();
    this.reset();
    this.authService.regenerateToken().subscribe(data => {
    })
  }
  logout() {
    this.childModal.hide();
    this.authenticationService.logout();
    this.router.navigate(['/'], { queryParams: { toNavigate: this.toNavigate } });
  }
}
