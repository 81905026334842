import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  public userEmail :any;

  constructor(
    private authenticationService: AuthenticationService,
    private router:Router
  ) { }

  ngOnInit() {
    this.getUserEmail();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['']);
  }

  getUserEmail(){
   this.userEmail = this.authenticationService.getUserEmail();
  }

}
