export const appToaster = {
  successHead: 'Success',
  errorHead: ' Attention',
  apiErrorHead: 'Attention',
  loginSucess: 'Login successful',
  logoutSucess: 'Log out successful',
  signUpSucess: 'Registration successful',
  forgetPasswordSuccess: 'OTP Sent',
  otpVerified: 'Account Verified',
  forgetPasswordError: 'Invalid User or Password'
};
