import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ImageUploaderComponent } from "./components/image-uploader/image-uploader.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Ng5SliderModule } from "ng5-slider";
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { ImageCropperModule } from "ngx-image-cropper";
import { RouterModule } from "@angular/router";
import { CustomToasterComponent } from "./components/custom-toaster/custom-toaster.component";

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  NgxSliderModule,
  Ng5SliderModule,
  ImageCropperModule,
];

const COMPONENTS = [ImageUploaderComponent, CustomToasterComponent];
@NgModule({
  declarations: [...COMPONENTS],
  imports: [...BASE_MODULES],
  exports: [...BASE_MODULES, ...COMPONENTS],
  // providers: [
  //   SharedModule
  // ]
})
export class SharedModule {}
