import { Injectable } from "@angular/core";
//import {io} from 'socket.io-client';
import * as Rx from "rxjs/Rx";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../../authentication/authentication.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import io from "socket.io-client";
import { Observer } from "rxjs/Observer";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  private socket: any;

  constructor(private authenticationService: AuthenticationService) {}

  sendMessage(messageText, appointmentId, senderId, receiverId) {
    // debugger
    this.socket.emit("sendMessage", {
      message: messageText,
      appointmentId: appointmentId,
      sender: senderId,
      receiver: receiverId,
    });
  }

  connectWithSocket() {
    let userId;
    this.authenticationService.getUserInfo().subscribe((user) => {
      if (user && user._id) {
        userId = user._id;
      }
      this.socket = io(`https://backend.hutano.com?userId=${userId}`);
    });
  }

  //onNewNotification() {
  // return Observable.create(observer => {
  //   if (this.socket) {
  //     this.socket.on('newEvent', msg => {
  //       observer.next(msg);
  //     });
  //   }
  // });
  // }

  // joinRoom(data) {
  // this.socket.emit('join', data);
  // }

  //onNewUserJoin() {
  // return Observable.create(observer => {
  //   this.socket.on('new user joined', msg => {
  //     observer.next(msg);
  //   })
  // })
  // }

  onDoctorJoinVWR() {
    //  return Observable.create(observer => {
    //   this.socket.on('doctorJoinsVWR', msg => {
    //     observer.next(msg);
    //   })
    // })
  }
  //   messageRecived;
  // public getNewMessage = () => {
  //   this.socket.on('receiveMessage', (message) =>{
  //     debugger
  //    this.message$.next(message.value);
  //   // let event1;
  // //    this.messageRecived.subscribe(event =>
  // //      event1 = JSON.parse(event.data));

  //  });
  //  return this.message$;
  // };
  observer: Observer<any>;

  getNewMessage(): Observable<any> {
    // debugger
    this.socket.on("receiveMessage", (res) => {
      // debugger
      this.observer.next(res);
    });

    return this.createObservable();
  }

  createObservable(): Observable<any> {
    return new Observable((observer) => {
      // debugger
      this.observer = observer;
    });
  }
}
