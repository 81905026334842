<!--------------------------------start of header------------------------------------------------>
<header id="top-header" class="fixed-top">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg">
      <a href="#">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </button>
      <div class="collapse navbar-collapse menu-bar" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">

          <li class="nav-item">
            <button class="btn btn-outline-success my-2 my-sm-0 Login-Btn" (click)="navigateTo('')">Login</button>
          </li>

        </ul>
        <form class="form-inline my-2 my-lg-0">
          <button class="btn btn-outline-success my-2 my-sm-0 get-started-btn"
            (click)="navigateTo('/register')">Register</button>
        </form>
      </div>
    </nav>
  </div>
</header>
<!--End of header-->