<footer class="footer-Bg">
  <div class="container-fluid">
    <div class="row">

      <div class="col-lg-5 col-md-5 col-sm-3">
        <div class="footer-Txt">
          <h3>© 2019 Mean-Crudtests</h3>
        </div>
      </div>

      <div class="col-lg-7 col-md-7 col-sm-9">
        <nav class="footer-link">
          <a href="#">Privacy </a>
          <a href="#">Accessibility</a>
          <a href="#">Terms</a>
        </nav>
      </div>

    </div>

  </div>
</footer>
