export const settingConfig = {
  licenceStates: [
    {
      key: "UK",
      value: "UK",
    },
    {
      key: "UP",
      value: "UP",
    },
    {
      key: "GJ",
      value: "GJ",
    },
  ],
  currentUseType: [
    {
      key: "Residential",
      value: "Residential",
    },
    {
      key: "Commerical",
      value: "Commerical",
    },
    {
      key: "Mixed Use",
      value: "Mixed Use",
    },
  ],
  currentStatus: [
    {
      key: "Vacant",
      value: "Vacant",
    },
    {
      key: "Occupied",
      value: "Occupied",
    },
  ],
  role: [
    {
      key: "Property Owner",
      value: "Property Owner",
    },
    {
      key: "Property Owners Agent",
      value: "Property Owners Agent",
    },
    {
      key: "Buyer",
      value: "Buyer (Auction or Sheriff Sale or Bank owned)",
    },
  ],
  ownerType: [
    {
      key: "Single Individual",
      value: "Single Individual",
    },
    {
      key: "Business Entity/Trust",
      value: "Business Entity/Trust",
    },
    {
      key: "Bank",
      value: "Bank",
    },
  ],
  buyerType: [
    {
      key: "Individual",
      value: "Individual",
    },
    {
      key: "Business",
      value: "Business",
    },
  ],
  businessType: [
    {
      key: "Corporation",
      value: "Corporation",
    },
    {
      key: "Partnership",
      value: "Partnership",
    },
    {
      key: "LLC",
      value: "LLC",
    },
  ],
  pointofContact: [
    {
      key: "Applicant",
      value: "Applicant",
    },
    {
      key: "Property Owner",
      value: "Property Owner",
    },
    {
      key: "Someone else",
      value: "Someone else",
    },
  ],
  appointmentType: [
    {
      key: "Office Appointment",
      value: "1",
    },
    {
      key: "Video Appointment",
      value: "2",
    },
    {
      key: "Onsite Appointment",
      value: "3",
    },
  ],
  appointmentStatus: [
    {
      key: "Pending",
      value: "0",
    },
    {
      key: "Accepted",
      value: "1",
    },
    {
      key: "Rejected",
      value: "2",
    },
    {
      key: "Initiated",
      value: "3",
    },
    {
      key: "Completed",
      value: "4",
    },
    {
      key: "Incomplete",
      value: "5",
    },
    {
      key: "Cancelled",
      value: "6",
    },
    {
      key: "Expired",
      value: "7",
    },
  ],

  parkingType: [
    {
      key: "Driveway",
      value: "1",
    },
    {
      key: "Street",
      value: "2",
    },
    {
      key: "Designated",
      value: "3",
    },
    {
      key: "Parking Garage",
      value: "4",
    },
  ],

  titleName: [
    {
      key: " MD",
      value: "Medical Doctor",
    },
    {
      key: " DDS",
      value: "Dentist",
    },
    {
      key: " DC",
      value: "Chiropractor",
    },
    {
      key: " PT",
      value: "Physical Therapist",
    },
    { key: " DO", value: "Osteopathic Doctor" },
    {
      key: " DPM",
      value: "Podiatrist",
    },
  ],

  bodyPartSide: [
    {
      key: "Left",
      value: "1",
    },
    {
      key: "Right",
      value: "2",
    },
    {
      key: "Top",
      value: "3",
    },
    {
      key: "Bottom",
      value: "4",
    },
    {
      key: "All Over",
      value: "5",
    },
    // {
    //   key: 'Front',
    //   value: '4'
    // },
  ],

  problemTimeSpan: [
    {
      key: "Hours",
      value: "1",
    },
    {
      key: "Days",
      value: "2",
    },
    {
      key: "Weeks",
      value: "3",
    },
    {
      key: "Months",
      value: "4",
    },
    {
      key: "Years",
      value: "5",
    },
  ],

  getMonthsNameByNumber: [
    {
      key: "January",
      value: "01",
    },
    {
      key: "February",
      value: "02",
    },
    {
      key: "March",
      value: "03",
    },
    {
      key: "April",
      value: "04",
    },
    {
      key: "May",
      value: "05",
    },
    {
      key: "June",
      value: "06",
    },
    {
      key: "July",
      value: "07",
    },
    {
      key: "August",
      value: "08",
    },
    {
      key: "September",
      value: "09",
    },
    {
      key: "October",
      value: "10",
    },
    {
      key: "November",
      value: "11",
    },
    {
      key: "December",
      value: "12",
    },
  ],

  medicationFrequency: [
    {
      key: "Once a day",
      value: "1",
    },
    {
      key: "Twice a day",
      value: "2",
    },
    {
      key: "Three times a day",
      value: "3",
    },
    {
      key: "Four Times a day",
      value: "4",
    },
    {
      key: "As needed (PRN)",
      value: "5",
    },
  ],

  conditionAffectingDailyActivity: [
    {
      key: "no effect",
      value: "1",
    },
    {
      key: "difficult",
      value: "2",
    },
    {
      key: "impossible",
      value: "3",
    },
  ],

  recreationalDrugs: [
    {
      key: "Marijuana",
      value: "1",
    },
    {
      key: "Coccaine",
      value: "2",
    },
    {
      key: "Methaphetamine",
      value: "3",
    },
    {
      key: "Heroine",
      value: "3",
    },
  ],

  drugsFrequency: [
    {
      key: "daily",
      value: "0",
    },
    {
      key: "rarely",
      value: "1",
    },
    {
      key: "socially",
      value: "2",
    },
  ],

  smokingFrequency: [
    {
      key: "1-10 cigarettes per day",
      value: "1",
    },
    {
      key: "Pack a day",
      value: "2",
    },
    {
      key: "More than pack a day",
      value: "3",
    },
  ],

  trackingStatusProvider: {
    INITIAL_STATUS: 0,
    PROVIDER_START_DRIVING: 1,
    PROVIDER_ARRIVED: 2,
    TREATMENT_STARTED: 3,
    TREATMENT_COMPLETED_PROVIDER: 4,
    TREATMENT_COMPLETED_PATIENT: 5,
    VERIFY_DOCTOR_ARRIVED_BY_PATIENT: 6,
  },

  userStatus: [
    {
      key: "Your Approval Pending",
      value: 0
    },
    {
      key: "You Accepted",
      value: 1
    },
    {
      key: "You Rejected",
      value: 2
    }
  ],
  userStatusOfDoctor: [
    {
      key: "Patient Approval Pending",
      value: 0
    },
    {
      key: "Patient Accepted",
      value: 1
    },
    {
      key: "Patient Rejected",
      value: 2
    }
  ],

  getKeyByProperty(value, property) {
    let key = "";
    settingConfig[property].forEach((elem) => {
      if (elem.value == value) {
        key = elem.key;
      }
    });
    return key;
  },

  convertTimeInAmPm(startTime) {
    // console.log(startTime);
    if (startTime) {
      let startTimeAmPm = "";
      let startTimeHour = startTime.split(":")[0];
      let startTimeMinute = startTime.split(":")[1];
      if (startTimeHour <= 11) {
        startTimeAmPm = startTimeHour + ":" + startTimeMinute + " AM";
      }
      if (startTimeHour >= 12) {
        if (startTime == 12) {
          startTimeAmPm = startTimeHour + ":" + startTimeMinute + " PM";
        } else {
          if (startTimeHour - 12 < 10) {
            startTimeAmPm =
              "0" + (startTimeHour - 12) + ":" + startTimeMinute + " PM";
          } else {
            startTimeAmPm = startTimeHour - 12 + ":" + startTimeMinute + " PM";
          }
        }
      }
      return startTimeAmPm;
    }
  },
};
