import { UsersService } from 'src/app/core/services';
import { AuthenticationService } from './../../../authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { MapsAPILoader } from '@agm/core';
// import * as $ from 'jquery'
import { environment } from 'src/environments/environment';
import { UsersExtendService } from 'src/app/core/services/users/users-extend.service';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as bootstrap from 'bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs-compat';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  @ViewChild('childModal') public childModal: ModalDirective;

  public currentUser;
  public isApplicationEditable = false;
  public specialities = [];
  public location = [];
  public userDetails: any = {};
  public userName: string;
  public userImage: string;
  public environment = environment;
  public showPaymentRedDot: boolean = false
  subscription: Subscription


  latitude: number;
  longitude: number;
  zoom: number;
  public address;
  selectLocation: string;
  private geoCoder;
  public isAuthenticated: any;
  isUserJoin = false;
  isDoctorJoin = false;

  @ViewChild('search')
  public searchElementRef: ElementRef;
  @ViewChild('template2') private template2;
  @ViewChild('template3') private template3;
  declare $: any;
  public rejoinMessage = '';
  public channelName = '';
  public providerReady = '';
  public isLogin = false;
  public notificationCount = null;
  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    public userAuthService: AuthenticationService,
    private usersService: UsersService,
    private userExtendService: UsersExtendService,
    private messagingService: MessagingService,
    private modalService: NgbModal,
    private activatedRoute:ActivatedRoute
  ) { }
  routeUrl
  ngOnInit() {
    this.isLogin = this.authenticationService.isLogin();
    this.getUserDetails();
    if (this.isLogin) {
      this.recieveNotification()

      this.checkPaymentDetail();
    }
    this.subscription = this.authenticationService.showPaymentRedDotFalse().subscribe(message => this.showPaymentRedDot = message)




    this.selectLocation = 'select';
    this.routeUrl = this.router.url
    this.getSpecialities();
    this.getChangedProfile();
    /** GET CURRENT ROUTE */
    this.setCurrentLocation();
    this.getUpdatedAddress();
    if (this.isLogin) {
      this.getNotificationCount();
    }


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngDoCheck() {
    this.address = this.authenticationService.getUserLocation();
    this.isAuthenticated = this.authenticationService.isLogin()
  }


  checkPaymentDetail() {
    this.userExtendService.checkPaymentDetail().subscribe(data => {
      if (data) {
        if (!data.CardAdded && !data.InsuranceAdded) {
          // this.notificationCount['count'] = this.notificationCount['count'] + 1;
          this.showPaymentRedDot = true;
        }
      }
    })
  }

  showPaymentRedDotFalse() {
    this.authenticationService.showPaymentRedDotFalse().subscribe(data => {
      if (data) {
        this.showPaymentRedDot = data
      }
    })
  }



  getUpdatedAddress() {
    this.userExtendService.getUpdatedAddress().subscribe(address => {
      if (address) {
        this.address = address;
      }
    })
  }

  private getSpecialities() {
    this.usersService.getSpecialities().subscribe(data => {
      if (data) {
        this.specialities = data;
      }
    })
  }

  goToDashboard() {
    this.router.navigateByUrl('/application?tab=what');
  }

  // Get Current Location Coordinates
  setCurrentLocation() {
    this.address = this.authenticationService.getUserLocation();
    if (!this.address) {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 8;
          this.getAddress(this.latitude, this.longitude);
        });
      }
    }
  }


  private getAddress(latitude, longitude) {
    let latLang = { lat: latitude, lng: longitude };
    let geocoder = new google.maps.Geocoder;
    geocoder.geocode({ 'location': latLang }, (result, status) => {
      let streetNumber = null;
      if (result[0].address_components && result[0].address_components[0]) {
        streetNumber = result[0].address_components[0].long_name;
      }
      let addressArray = result[result.length - 4];
      let city = addressArray.address_components[0].long_name;
      let state = addressArray.address_components[1].long_name;
      let country = addressArray.address_components[2] ? addressArray.address_components[2].long_name : '';
      let address = result[0].formatted_address;
      let locationObject = {
        address: city,
        city: city,
        state: state,
        country: country,
        latitude,
        longitude
      }
      if (streetNumber) {
        locationObject['streetNumber'] = streetNumber;
      }
      this.location = [locationObject];
      localStorage.setItem('locationDetails', JSON.stringify(this.location));
    });
  }

  getUserDetails() {
    this.userDetails = this.authenticationService.getUserDetails();
    if (this.userDetails) {

      this.userName = this.userDetails['fullName']
      this.userImage = this.userDetails['avatar']
    }

  }

  getChangedProfile() {
    this.authenticationService.getChangedProfile().subscribe(data => {
      if (data) {
        this.userDetails = data;
        this.userName = this.userDetails['fullName']
        this.userImage = this.userDetails['avatar']
      }
    })
  }

  logout() {
    this.authenticationService.userLogout();
    sessionStorage.clear();
    this.userImage = ''
    this.router.navigate(['']);
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  goToProfile(type?: string) {
    if (type && type != '') {
      this.router.navigate([type]);
      return false;
    }
    this.router.navigate(['/personal-information']);
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  loginSignUp(path) {
    let toNavigate = this.router.url;
    this.router.navigate([path], { queryParams: { toNavigate } })
  }

  recieveNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (this.notificationCount) {
        this.notificationCount['count'] = this.notificationCount['count'] + 1;
      }
      this.routeUrl = this.router.url;
      if (res && res.data && res.data.notification_type == "call_join" && this.routeUrl.split('?')[0] != '/call') {
        this.rejoinMessage = res.notification.body;
        this.channelName = res.data.appointmentId;
        this.openRejoinModal();
      }
      if (res && res.data && res.data.notification_type == "ready_to_join" &&
        this.routeUrl.split('?')[0] != '/call' && this.routeUrl != '/appointment-status/' + res.data.appointmentId) {
        this.providerReady = res.notification.body;
        this.channelName = res.data.appointmentId;
        this.openReadyModal()
      }
    })
  }

  openRejoinModal() {
    document.getElementById("joinModal").click();
  }

  openReadyModal() {
    document.getElementById("readyModal").click();
  }

  closeRejoinModal() {
    this.modalService.dismissAll();
  }


  rejoinCall() {
    this.closeRejoinModal();
    this.router.navigate(['/call'],
      {
        queryParams: {
          callType: 'Video',
          appointmentId: this.channelName
        }
      });
  }

  goToAppointmentList() {
    this.router.navigate(['/appointment-status', this.channelName])
  }

  getNotificationCount() {
    this.userExtendService.getNotificationCount().subscribe(data => {
      this.notificationCount = data;
    });
  }

  goToNotification() {
    this.userExtendService.readNotificationCount().subscribe(data => {
      if (data.ok) {
        this.notificationCount['count'] = 0;
      }
    });
    this.router.navigate(['/notifications']);
  }

}
