import { AuthenticationService } from './../../../authentication/authentication.service';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  @ViewChild('template') private template3;
  public rejoinMessage = '';
  public channelName = '';
  public routeUrl = ''
  public hideHeader = false;
  constructor(
    public authenticationService: AuthenticationService,
    private messagingService: MessagingService,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit() {
    this.routeUrl = this.router.url
    const tempUser = this.authenticationService.getTempUser();
    if (tempUser) {
      this.hideHeader = true
    }
  }

  recieveNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res && res.data && res.data.notification_type == "call_join") {
        this.rejoinMessage = res.data.body;
        this.channelName = res.data.appointmentId;
        this.openRejoinModal();
      }
    })
  }

  openRejoinModal() {
    this.modalService.open(this.template3)
  }

  closeRejoinModal() {
    this.modalService.dismissAll()
  }

  rejoinCall() {
    this.router.navigate(['/call'],
      {
        queryParams: {
          callType: 'Audio',
          appointmentId: this.channelName
        }
      });
  }

}
