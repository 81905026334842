<div class="modal fade uploadImg" [id]="modalId" data-backdrop="static" data-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropsixLabel" aria-hidden="true" style="overflow-y: auto; padding-bottom:200px;">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="popupImg_select">
        <h1>{{title}}</h1>
        <div id="filImg" class="pick-upImg">
          <input type="file" *ngIf="!isImageChangedEvent" name="shop-icon" id="shop-icon" class="inputfile"
            (change)="fileChangeEvent($event)" />
          <image-cropper *ngIf="isImageChangedEvent" [imageChangedEvent]="isImageChangedEvent" format="png, jpeg"
            [canvasRotation]="canvasRotation" [imageQuality]="70" (imageCropped)="imageCropped($event)"
            [transform]="cropperImageTransform" [maintainAspectRatio]="false">
          </image-cropper>
          <ngx-slider *ngIf="isImageChangedEvent" [(value)]="scale" [options]="sliderConfig"
            (valueChange)="zoom($event)">
          </ngx-slider>

          <label class="file-upload-label" *ngIf="!isImageChangedEvent">
            <img *ngIf="!isImageChangedEvent" src="assets/images/upload-popup.svg" class="img-fluid mx-auto icon-img" />
            <div class="label-text">Browse</div>
          </label>
        </div>
        <div class="d-flex justify-content-between" *ngIf="isImageChangedEvent">
          <button class="btn btn-rotate-left" (click)="rotateImage(-1)"> -90°
            <img class="img-fluid d-block reverse-img" src="assets/images/rotate-left.svg">
          </button>
          <button class="btn btn-rotate-right" (click)="rotateImage(+1)"> +90°
            <img class="img-fluid d-block reverse-img" src="assets/images/rotate-right.svg">
          </button>
        </div>
        <div class="row align-center">
          <div class="col-md-12 mt-5">
            <ng-content select="[modalForm]" *ngIf="isImageChangedEvent"></ng-content>
          </div>
          <div class="col-md-4 mt-5">
            <div class="upload-new-img" *ngIf="isImageChangedEvent">
              <input type="file" (change)="fileChangeEvent($event)">
              <label><span><img src="assets/images/cloud-computing.png" class="img-fluid"></span>Upload New</label>
            </div>
          </div>
          <div class="col-md-8">
            <div class="popup_uploadBtn">
              <button type="button" class="ub1 mx-4" data-dismiss="modal" value="reset"
                (click)="removeImage()">Cancel</button>
              <button type="button" class="ub2" [attr.data-dismiss]="!stopClosingModal ? 'modal' : ''" value="submit"
                (click)="cropImage()" [disabled]="!croppedImage">Submit</button>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
