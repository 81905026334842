import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxImageCompressService } from 'ngx-image-compress';
import { el } from 'date-fns/locale';


@Injectable({
  providedIn: "root",
})
export class UsersExtendService {

  public updatedAddress = new BehaviorSubject(null);
  constructor(
    private http: HttpClient,
    private router: Router,
    private toaster: ToastrService,
    private imageCompress: NgxImageCompressService
  ) {
  }

  setUpdatedAddress(address) {
    this.updatedAddress.next(address);
  }

  getUpdatedAddress(): Observable<any> {
    return this.updatedAddress.asObservable();
  }

  computeDistanceBwPoints(origin, destination) {
    let origin1 = new google.maps.LatLng(origin.lat,
      origin.lng);
    let destinationA = new google.maps.LatLng(destination.lat, destination.lng);
    let distance = google.maps.geometry.spherical.computeDistanceBetween(
      origin1, destinationA);
    return distance;
  }

  getOnDemandDoctors(coordinates): Observable<any> {
    const href = environment['getOndemandDoctor'];
    return this.http.get<any>(href, { params: coordinates }).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getPatientReasons(): Observable<any> {
    const href = environment['patientRasons'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  postPatientReview(toSend): Observable<any> {
    const href = environment['savePatientReview'];
    return this.http.post<any>(href, toSend).pipe(map((data: any) => {
      if (data.status == 'success') {
        this.toaster.success("Review submitted successfully.")
        return data.response;
      }
    }));
  }

  checkPaymentDetail(): Observable<any> {
    const href = environment['checkPaymentDetail'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getPaymentHistory(): Observable<any> {
    const href = environment['getPaymentHistory'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }))
  }

  getDiagnosticTestTypes(): Observable<any> {
    const href = environment['getDiagnosticTestTypes'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }))
  }

  getNotificationCount(): Observable<any> {
    const href = environment['getNotificationCount'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }))
  }

  readNotificationCount(): Observable<any> {
    const href = environment['readNotificationCount'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }))
  }

  getSliderOption(loop, type) {
    let option: OwlOptions = {
      loop: loop,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      navText: ['', ''],
      nav: true,
      margin: 50,
      autoWidth: true,
    }
    if (type == 'ondemand' || type == 'saved_doctor' || type == 'two_way') {
      option['responsive'] = {
        320: {
          items: 1
        },
        360: {
          items: 1
        },
        375: {
          items: 1
        },
        414: {
          items: 1
        },
        767: {
          items: 1
        },
        991: {
          items: 2
        },
        1280: {
          items: 3,
          dots: false,
        }
      }
    }
    if (type == 'top_speciality') {
      option['responsive'] = {
        320: {
          items: 1
        },
        360: {
          items: 1
        },
        375: {
          items: 1
        },
        414: {
          items: 1
        },
        767: {
          items: 3
        },
        991: {
          items: 3
        },
        1024: {
          items: 3
        }
      }
      if (!loop) {
        option['margin'] = 70;
      } else {
        option['margin'] = 200;
      }
    }
    if (type == 'provider_types') {
      option['responsive'] = {
        320: {
          items: 1
        },
        360: {
          items: 1
        },
        375: {
          items: 2
        },
        414: {
          items: 3
        },
        767: {
          items: 6
        },
        991: {
          items: 6
        },
        1024: {
          items: 6
        }
      }
    }
    if (type == 'speciality') {
      option['responsive'] = {
        320: {
          items: 1
        },
        360: {
          items: 1
        },
        375: {
          items: 2
        },
        414: {
          items: 3
        },
        767: {
          items: 8
        },
        991: {
          items: 8
        },
        1024: {
          items: 8
        }
      }
    }
    return option
  }

  compressFile(image) {
    return new Promise((resolve, reject) => {
      let fileSize = (this.imageCompress.byteCount(image) / 1024)
      console.warn('Size in kb was:', fileSize);
      if (fileSize <= 300) {
        resolve(image);
      }
      this.imageCompress.compressFile(image, -1, 50, 50).then(
        result => {
          let compressedSize = (this.imageCompress.byteCount(result) / 1024);
          console.warn('Size in kb is now:', compressedSize);
          if (compressedSize > 300) {
            this.compressFile(result);
          }
          resolve(result);
        }
      );
    })

  }


  getPatientDashboardData(): Observable<any> {
    const href = environment['getPatientDashboardData'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getProvidersNetwork(): Observable<any> {
    const href = environment['getProvidersNetwork'];
    return this.http.get<any>(href).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  deleteProvidersNetwork(body): Observable<any> {
    const href = environment['deleteProvidersNetwork'];
    return this.http.post<any>(href, body).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  deleteProviderGroup(body): Observable<any> {
    const href = environment['deleteProviderGroup'];
    return this.http.post<any>(href, body).pipe(map((data: any) => {
      if (data.status == 'success') {
        return data.response;
      }
    }));
  }

  getProviders(search) {
    const href = environment["getProviders"];
    return this.http.get<any>(href, { params: search })
      .pipe(
        map((data: any) => {
          if (data.status == "success") {
            return data.response;
          }
        })
      );
  }
  getProvidersGroup() {
    const href = environment["getProvidersGroup"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addEditProvidersGroup(body) {
    const href = environment["addEditProvidersGroup"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getPatientRelationList() {
    const href = environment["getPatientRelationList"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getFamilyMembers() {
    const href = environment["getFamilyMembers"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addFamilyMembers(body) {
    const href = environment["addFamilyMembers"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }


  getAboutUsContent() {
    const href = environment["getAboutUsContent"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getContactUsContent() {
    const href = environment["getContactUsContent"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  postContactUsForm(body) {
    const href = environment["postContactUsForm"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getAppDownloadLink(phoneNumber) {
    const href = environment["getAppDownloadLink"];
    return this.http.get<any>(href, { params: phoneNumber })
      .pipe(
        map((data: any) => {
          if (data.status == "success") {
            return data.response;
          }
        })
      );
  }



}
