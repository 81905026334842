import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap, map } from "rxjs/operators";
import { Observable, BehaviorSubject, of } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { AuthenticationService } from "src/app/core/authentication/authentication.service";

import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Register } from "src/app/shared";
import { OccupancyApplication } from "../../models/users";
import { data, param } from "jquery";
import { env } from "process";

const credentialsKey = "currentUser";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  public BASE64_MARKER = ";base64,";

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toaster: ToastrService
  ) {}

  getProviders() {
    const lStore = JSON.parse(localStorage.getItem("featuredProvider") || "[]");
    if (lStore.length > 0) {
      return of(lStore);
    }

    const href = environment["providers"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          localStorage.setItem(
            "featuredProvider",
            JSON.stringify(data.response)
          );
          return data.response;
        }
      })
    );
  }
  getSpecialities() {
    const href = environment["getSpecialities"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  searchProfessionals(search): Observable<any> {
    const href = environment["searchProfessionals"];
    return this.http.get<any>(href, { params: search }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  searchServicesByName(search): Observable<any> {
    const href = environment["searchServicesByName"];
    return this.http.get<any>(href, { params: search }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  specialitiesWithTitle(): Observable<any> {
    const href = environment["specialitiesWithTitle"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  //provider list page
  providerLists(body) {
    const href = environment["providerList"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  searchProviders(body) {
    const href = environment["searchProviders"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getProfessionalTitleLists() {
    const href = environment["professionalListTitle"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getspecialityLists() {
    const href = environment["specialtiesList"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getDegreeLists() {
    const href = environment["degreeList"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getLanguageLists() {
    const href = environment["languages"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  //end of provider list page

  //two way provider list
  twoWayProviderLists(id) {
    const href = environment["twoWayProvider"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  //get states api
  getStates(): Observable<any> {
    const href = environment["getStates"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  popularProvider() {
    const href = environment["popularProvider"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  AllTopSpecialityProvider(id) {
    const href = environment["AllTopSpecialityProvider"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  //provider list page
  resetPassword(body) {
    const href = environment["resetPassword"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          // this.router.navigate(['/verify-otp']);
          return data;
        }
      })
    );
  }

  resetUserPassword(body) {
    const href = environment["resetUserPassword"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          // this.router.navigate(['/verify-otp']);
          return data.response;
        }
      })
    );
  }

  verifyOtp(body) {
    const href = environment["resetPassword"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  verifyEmailOtp(body) {
    const href = environment["verifyEmail"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  signUp(body) {
    const href = environment["signUp"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          if (body.step == 2) {
            const storage = localStorage;
            storage.setItem(credentialsKey, JSON.stringify(data.response));
            this.router.navigate(["/"]);
          }
          return data.response;
        }
      })
    );
  }

  resendOtp(body) {
    const href = environment["resendPhoneCode"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  resendEmailOtp(body) {
    const href = environment["resendCodeCode"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  // Doctor Details
  docterDetails(id) {
    const href = environment["docterDetails"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  // book appointment
  appointmentBooking(id) {
    const href = environment["bookAppointment"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  // slots booking
  // getSlotBooking(body) {
  //   const href = environment["doctorScheduleDetails"];
  //   return this.http.post<any>(href,body).pipe(
  //     map((data: any) => {
  //       if (data.status == "success") {
  //         return data.response;
  //       }
  //     })
  //   );
  // }

  //medical history

  //get diseases
  getDiseases() {
    const href = environment["getMedicalHistory"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getMedicalHistoryData() {
    const href = environment["getSelectedMedicalData"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  setPaymentIntent() {
    const href = environment["setPaymentIntent"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getSavedInsurance() {
    const href = environment["getSavedInsurance"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getInsuranceUpdate(body) {
    const href = environment["updateInsurance"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerFilterOptions(): Observable<any> {
    const href = environment["providerFilterOption"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  deleteCard(id) {
    let href = environment["removeCard"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  saveMedicalHistory(medicalHistory): Observable<any> {
    const href = environment["saveMedicalHistory"];
    return this.http.post<any>(href, medicalHistory).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getSavedHistory() {
    const href = environment["saveMedicalHistory"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  saveAddress(toSend): Observable<any> {
    const href = environment["saveAddress"];
    return this.http.post<any>(href, toSend).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Address saved successfully");
          return data.response;
        }
      })
    );
  }

  getSavedAddress(): Observable<any> {
    const href = environment["getAddress"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  editAddress(toSend): Observable<any> {
    const href = environment["editAddress"];
    return this.http.post<any>(href, toSend).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  deleteAddress(id): Observable<any> {
    const href = environment["deleteAddress"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Address deleted successfully.");
          return data.response;
        }
      })
    );
  }

  editProfile(profileData): Observable<any> {
    const href = environment["editProfile"];
    return this.http.post<any>(href, profileData).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Profile updated successfully.");
          return data.response;
        }
      })
    );
  }

  removeInsurance(insuranceId): Observable<any> {
    const href = environment["removeInsurance"];
    return this.http.post<any>(href, insuranceId).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Insurance deleted successfully.");
          return data.response;
        }
      })
    );
  }

  getSavedDoctors(coordinates): Observable<any> {
    const href = environment["getSavedDoctors"];
    return this.http.get<any>(href, { params: coordinates }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getNotificationList() {
    const href = environment["getNotificationList"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  saveUserMedicalImages(file): Observable<any> {
    const href = environment["patientMedicalImages"];
    return this.http.post<any>(href, file).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Saved successfully.");
          return data.response;
        }
      })
    );
  }

  saveMedicalDocuments(file): Observable<any> {
    const href = environment["patientMedicalDocuments"];
    return this.http.post<any>(href, file).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Saved successfully.");
          return data.response;
        }
      })
    );
  }

  saveMedicalDiagnostics(file): Observable<any> {
    const href = environment["patientMedicalDiagnostics"];
    return this.http.post<any>(href, file).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Saved successfully.");
          return data.response;
        }
      })
    );
  }

  getMedicalImagesAndDoc(value) {
    const href = environment["getMedicalImagesDocuments"];
    return this.http.get<any>(href, { params: value }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  deleteMedicalImage(id): Observable<any> {
    const href = environment["deleteMedicalImage"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Deleted successfully.");
          return data.response;
        }
      })
    );
  }
  deleteMedicalDocuments(id): Observable<any> {
    const href = environment["deleteMedicalDocuments"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Deleted successfully.");
          return data.response;
        }
      })
    );
  }

  deleteMedications(id): Observable<any> {
    const href = environment["deleteMedications"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.toaster.success("Deleted successfully.");
          return data.response;
        }
      })
    );
  }
  deletePatientPreferredPharmacy(id) {
    const href = environment["deletePatientPreferredPharmacy"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  // deletePatientPreferredPharmacy(id): Observable<any> {
  //   const href = environment['deletePatientPreferredPharmacy'];
  //   return this.http.post<any>(href, id).pipe(map((data: any) => {
  //     if (data.status == 'success') {
  //       this.toaster.success("Deleted successfully.");
  //       return data.response;
  //     }
  //   }));
  // }

  getPagesContent() {
    const href = environment["getPagesContent"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  /**Shared Function to convert base64image to blob */
  convertBase64ToBlob(image, fileType) {
    let convertedFile = this.convertDataURIToBinary(image);
    let blob = new File(
      [convertedFile],
      `medical-image.${fileType.split("/")[1]}`,
      { type: fileType }
    );
    return blob;
  }

  convertDataURIToBinary(dataURI) {
    var base64Index =
      dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  getOtpOverCall(body) {
    const href = environment["getOtpOverCall"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  checkPatientPrimaryInsurance(): Observable<any> {
    const href = environment["checkPatientPrimaryInsurance"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addPatientInsurance(formData): Observable<any> {
    const href = environment["addPatientInsurance"];
    return this.http.post(href, formData).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getRecentChatsList() {
    // debugger
    const href = environment["recentChats"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  upcomingAppoinmentChat(param) {
    // debugger
    // const href = environment['recent-chats'];
    // let body;
    const href = environment["searchDoctorAppoinment"];
    return this.http.get<any>(href, { params: param }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getChatDetails(param) {
    // debugger
    // const href = environment['recent-chats'];
    // let body;
    const href = environment["chatDetails"];
    return this.http.get<any>(href, { params: param }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getAllReferredProviders(page) {
    const href = environment["getAllReferredProviders"];
    return this.http.get<any>(`${href}?page=${page}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  getSingleReferredProvider(providerId) {
    const href = environment["getSingleReferredProvider"];
    return this.http.get<any>(`${href}/${providerId}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  getDoctorGroup() {
    const href = environment["getDoctorsGroup"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getReplaceDoctor(id, latitude, longitude) {
    let params = {
      latitude,
      longitude,
    };
    const href = environment["getReplaceDoctor"];
    return this.http.get<any>(`${href}/${id}`, { params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  updateReplaceDoctor(payload) {
    const href = environment["updateReplaceDoctor"];
    return this.http.post<any>(`${href}`, payload).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getGroupDetails(id, params?) {
    const href = environment["getGroupDetails"];
    return this.http.get<any>(`${href}/${id}`, { params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
}
