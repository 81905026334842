import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthenticationService } from "../../authentication/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  postUserRegistration(formData): Observable<any> {
    const href = environment["postRegister"];
    return this.http.post(href, formData).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addPatientInsurance(formData): Observable<any> {
    const href = environment["addPatientInsurance"];
    return this.http.post(href, formData).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  checkEmailOrPhoneExist(value): Observable<any> {
    const href = environment["isEmailExist"];
    return this.http.get(href, { params: value }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  userRegister(body): Observable<any> {
    const href = environment["sendMobileOtp"];
    return this.http.post(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  verifyPhoneOtp(body): Observable<any> {
    const href = environment["verifyPhoneOtp"];
    return this.http.post(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  setRegistrationPassword(body): Observable<any> {
    const href = environment["setRegistrationPassword"];
    return this.http.post(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  changePatientPassword(body): Observable<any> {
    const href = environment["changePatientPassword"];
    return this.http.post(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  emailVerify(body): Observable<any> {
    const href = environment["emailVerify"];
    return this.http.post(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          data.user["token"] = data.token;
          this.authenticationService.setUser(data.user);
          return data.response;
        }
      })
    );
  }

  regenerateToken(): Observable<any> {
    const href = environment["regenerateToken"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.authenticationService.logout();
          this.authenticationService.setUser(data.response);
          return data.response;
        }
      })
    );
  }

  getRegistrationStatus(toSend): Observable<any> {
    const href = environment["getRegistrationStatus"];
    return this.http.get<any>(href, { params: toSend }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.authenticationService.logout();
          return data.response;
        }
      })
    );
  }

  setRegisterData(data) {
    sessionStorage.setItem("registrationData", JSON.stringify(data));
  }

  getRegisterData() {
    return JSON.parse(sessionStorage.getItem("registrationData"));
    // return JSON.parse(localStorage.getItem('currentUser'));
  }

  removeRegisterData() {
    // sessionStorage.removeItem('registrationData');
  }

  createNewUserRequest(body): Observable<any> {
    const href = environment["createNewPatient"];
    return this.http.post(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  editPatient(body): Observable<any> {
    const href = environment["editPatient"];
    return this.http.post(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
}
