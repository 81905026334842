import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoLeadingSpace]'
})
export class NoLeadingSpaceDirective {

  constructor(
    private elem: ElementRef
  ) { }

  @HostListener('input', ['$event'])
  onInputChange(event) {
    let initialValue = this.elem.nativeElement.value;
      this.elem.nativeElement.value = initialValue.replace(/ +(?= )/g,'');
  }

}
