import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { MessagingService } from "src/app/core/services/messaging.service";
import { ProvidersService } from "src/app/core/services/providers";
import { UsersExtendService } from "src/app/core/services/users/users-extend.service";

@Component({
  selector: "app-organization-header",
  templateUrl: "./organization-header.component.html",
  styleUrls: ["./organization-header.component.css"],
})
export class OrganizationHeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private toasterService: ToastrService,
    public authenticationService: AuthenticationService,
    private providersService: ProvidersService,
    private modalService: NgbModal,
    private userExtendService: UsersExtendService,
    private messagingService: MessagingService
  ) {}
  public routeUrl;
  public userName: any;
  ngOnInit(): void {
    const userDetails = JSON.parse(localStorage.getItem("organizationUser"));
    this.userName = userDetails["title"] + ' ' + userDetails["firstName"];
    console.log(userDetails, 'ss')
  }
  logout() {
    this.authenticationService.organizationLogout();
    this.router.navigate(["authentication/organization/login"]);
    sessionStorage.clear();
    localStorage.clear();
  }
}
