import { UsersService } from 'src/app/core/services';
import { AuthenticationService } from './../../../authentication/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { MapsAPILoader } from '@agm/core';
import * as $ from 'jquery'
import { environment } from 'src/environments/environment';
import { ProvidersService } from 'src/app/core/services/providers';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { appToaster } from 'src/app/configs';
import { UsersExtendService } from 'src/app/core/services/users/users-extend.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'provider-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class ProviderHeaderComponent implements OnInit {
  public currentUser;
  public isApplicationEditable = false;
  public registrationComplete = false;
  public location = [];
  public appointmentList = [];

  public userDetails = {};
  public userName: string;
  public userImage: string;
  public environment = environment;
  public notifications = <any>[];
  public timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  hideModel = true;
  latitude: number;
  longitude: number;
  public isAuthenticated = true;
  public isOnline = false;
  appointmentDetail=null
  public errorList = [];
  public providerReady = '';
  public notificationCount: number = 0;

  public currentLocation;
  public rejoinMessage = '';
  public channelName = '';
  onlineBody = {}
  providerCancelBulkAppointmentBody = {}
  appointmnetIds = [];

  constructor(
    private router: Router,
    private toasterService: ToastrService,
    public authenticationService: AuthenticationService,
    private providersService: ProvidersService,
    private modalService: NgbModal,
    private userExtendService: UsersExtendService,
    private messagingService: MessagingService
  ) { }
  routeUrl
  ngOnInit() {
    this.trackProviderOnlineOffline()
    this.routeUrl = this.router.url
    this.getUserDetails();
    this.getAppointmentNotification();
    if (this.isAuthenticated == true) {
    }
    this.getUserLocation();
    if (this.authenticationService.isLogin() && this.routeUrl.split('?')[0] != '/provider/call') {
      this.recieveNotification()
    }
  }
  ngDoCheck() {
    this.isAuthenticated = this.authenticationService.isLogin()
  }

  trackProviderOnlineOffline() {
    let timeZone = this.timeZone
    this.providersService.trackProviderOnlineOffline({ timeZone }).subscribe(res => {

      if (res.isOnline == false) {
        this.isOnline = false
        $("#checkValue").prop('checked', false);
      }
      else if (res.isOnline == true) {
        this.isOnline = true
        $("#checkValue").prop('checked', true);
      }
    })

  }

  toggleOnlineOffline() {
    if (this.isOnline == true) {
      this.providersService.makeProviderOffline().subscribe(res => {
        this.toasterService.success(appToaster.successHead, res.response);
      })
      this.isOnline = false
      $("#checkValue").prop('checked', false);

    }
    else {
      this.onlineBody['timeZone'] = this.timeZone;
      this.providersService.makeProviderOnline({
        ...this.onlineBody,
      }).subscribe(res => {
        this.toasterService.success(appToaster.successHead, res.response);
        this.isOnline = true
        $("#checkValue").prop('checked', true);
      },
        (err) => {
          // this.isOnline = false
          $("#checkValue").prop('checked', false);
        });

    }
  }

  checkProviderOnDemandAppointments() {
    this.appointmnetIds = [];
    if (this.isOnline == true) {
      this.providersService.checkProviderOnDemandAppointments().subscribe(data => {
        data.response.forEach(element => {
          this.appointmnetIds.push(element._id);
        });
        if (this.appointmnetIds.length > 0) {
          $("#checkValue").prop('checked', false);
          document.getElementById("bulkAppointmentErrorModal").click();
        }
        else {
          this.toggleOnlineOffline();
        }
        return
      })
    }
    else {
      this.toggleOnlineOffline();
    }
  }

  closeCancelAppointmentModal() {
    this.isOnline = true;
  }

  providerCancelBulkAppointment() {
    this.providerCancelBulkAppointmentBody = {}
    this.providerCancelBulkAppointmentBody['appointmnetIds'] = this.appointmnetIds
    this.providersService.providerCancelBulkAppointment({
      ...this.providerCancelBulkAppointmentBody,
    }).subscribe(data => {
      if (data) {
        this.toasterService.success(appToaster.successHead, 'Bulk Appointment Canceled');
        // this.addBlockScheduleForm.reset();
        document.getElementById("dismissModal").click();
        this.toggleOnlineOffline();
        return true;

      }
    })
  }

  getAppointmentNotification() {
    this.appointmentList = [];
    if (this.router.url !== '/provider/provider-activity') {
      this.providersService.unreadProviderNotification().subscribe(res => {
        if (res.registrationComplete == true) {
          this.registrationComplete = true
        }
        this.appointmentList = res.notification
        this.notificationCount = this.appointmentList.length
        // if (this.appointmentList.length > 0) {
        //   this.appointmentList.forEach(elem => {
        //     // this.hideModel = false;
        //     this.notificationCount++

        //     var day = elem.appointment?.date
        //     let todayDate = moment().format('YYYY/MM/DD')
        //     // elem.user.createdAt = moment(elem.user.createdAt).format("YYYY");
        //     elem.appointment.appointmentDay = moment(elem.appointment?.date).format("dddd");
        //     elem.appointment.appointmentDate = moment(elem.appointment?.date).format("MM/DD/YYYY");
        //     // elem.fromTime = moment.utc(todayDate + ' ' + elem.fromTime).tz(this.timeZone).format("hh:mm A")
        //     elem.appointment.fromTime = moment.utc(day).tz(this.timeZone).format("hh:mm A")
        //     // elem.toTime = moment.tz(todayDate + ' ' + elem.toTime, this.timeZone).utc().format('HH:mm');
        //   })

        // }

        let i = 0;

        if (res.verificationNotification?.length > 0) {
          // this.hideModel = false;
          res.verificationNotification[0]?.stripeVerificationRequirements?.eventually_due?.forEach(elem => {
            if (elem == 'individual.address.city' || elem == 'individual.address.line1' || elem == 'individual.address.postal_code' || elem == 'individual.address.state') {
              if (i >= 1) {
                return
              }
              this.errorList.push({ message: 'There is some error in your address, city state combination, click here to change it', errorType: 'personalInformationError' })
              i++
              this.notificationCount++

            }
            if (elem == 'individual.verification.document') {
              this.errorList.push({ message: 'Please upload verification documents, click here to update it', errorType: 'verificationError' })
              this.notificationCount++

            }
            if (elem == 'external_account') {
              this.errorList.push({ message: 'Please Add External Account, click here to add it', errorType: 'externalAccountError' })
              this.notificationCount++
            }
          })
        }
        if (res.responseBack.insuranceAdded == false) {
          this.errorList.push({ message: 'Please Add External Account, click here to add it', errorType: 'externalAccountError' })
          this.notificationCount++
        }

        // if (this.appointmentList.length > 0) {
        //   this.hideModel = false;
        // }

      })
    }

  }

  getUserLocation() {
    this.latitude = this.authenticationService.getUserLocation().latitude;
    this.longitude = this.authenticationService.getUserLocation().longitude;
  }

  getUserDetails() {
    this.userDetails = this.authenticationService.getUserDetails();
    this.userName = this.userDetails['fullName']
    this.userImage = this.userDetails['avatar']
  }

  logout() {
    this.authenticationService.providerLogout();
    this.router.navigate(['authentication/provider/login']);
    sessionStorage.clear();
  }

  navigateTo(route) {
    this.router.navigate([route]);
  }

  goToProfile() {
    this.router.navigate(['/my-account'], { queryParams: { type: 'notifications' } });
  }

  hideNotificationModel() {
    this.providersService.readProviderNoitification().subscribe(res => {
      this.hideModel = true;
      this.appointmentList = [];
      this.errorList = [];
      this.messagingService.currentMessage = new BehaviorSubject(null);
    })
  }

  redirectToRequestDetail(_id) {
    this.router.navigate(['/provider/appointment-request-detail', _id]);
  }

  redirectToProviderActivity() {
    this.hideModel = true;
    this.appointmentList = [];
    this.router.navigate(['/provider/activity']);
  }

  recieveNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res && res.data && res.data.notification_type == "booking_request") {
        res.data.appointmentTime = moment.tz(res.data.appointmentDate, this.timeZone).format("dddd, DD MMMM, hh:mm A");
        this.appointmentDetail = res.data
        // this.appointmentList.push({appointmentId: res.data.appointmentId, appointmentTime: res.data.appointmentTime, avatar: res.data.avatar, fullName :res.data.fullName})
        this.notificationCount++;
        this.hideModel = false;
      }
      if (res && res.data && res.data.notification_type == "call_join") {
        this.rejoinMessage = res.notification.body;
        this.channelName = res.data.appointmentId;
        this.openRejoinModal();
      }
      if (res && res.data && res.data.notification_type == "ready_to_join") {
        if(this.routeUrl != '/provider/appointment-status/' + res.data.appointmentId && this.routeUrl != '/provider/appointment-detail/' + res.data.appointmentId){
        this.providerReady = res.notification.body;
        this.channelName = res.data.appointmentId;
        this.openReadyModal()
      }
      }
    })
  }

//   openAppointmentModal(data){
//     // this.appointmentDetail = null

//     // this.appointmentDetail = data

//     // this.appointmentList.push({appointmentId: data.appointmentId, appointmentTime: data.appointmentTime, avatar: data.avatar, fullName :data.fullName})
//     // this.appointmentList.push({appointmentId: data.appointmentId, appointmentTime: data.appointmentTime, avatar: data.avatar, fullName :data.fullName})
//     // this.appointmentList.push({appointmentId: data.appointmentId, appointmentTime: data.appointmentTime, avatar: data.avatar, fullName :data.fullName})

//     // if (this.appointmentList.length > 0) {
//       // this.appointmentList.forEach(elem => {
//         // this.hideModel = false;
//         this.notificationCount++

//         // var day = elem.appointmentDate
//         // let todayDate = moment().format('YYYY/MM/DD')
//         // // elem.user.createdAt = moment(elem.user.createdAt).format("YYYY");
//         // elem.appointment.appointmentDay = moment(elem.appointment?.date).format("dddd");
//         // elem.appointment.appointmentDate = moment(elem.appointment?.date).format("MM/DD/YYYY");
//         // elem.fromTime = moment.utc(todayDate + ' ' + elem.fromTime).tz(this.timeZone).format("hh:mm A")
//         // elem.toTime = moment.tz(todayDate + ' ' + elem.toTime, this.timeZone).utc().format('HH:mm');
//       // })

//     // }


//     this.hideModel = false;
//   // }
// }

  openReadyModal() {
    document.getElementById('providerReadyModal').click();
  }

  openRejoinModal() {
    document.getElementById('providerJoinModal').click();
  }

  closeRejoinModal() {
    this.modalService.dismissAll()
  }

  goToAppointmentList() {
    this.closeRejoinModal()
    this.router.navigate(['/provider/appointment-status', this.channelName])
  }

  rejoinCall() {
    this.closeRejoinModal();
    this.router.navigate(['/provider/call'],
      {
        queryParams: {
          callType: 'Audio',
          appointmentId: this.channelName
        }
      });
  }

  goToLogin() {
    this.router.navigate(['authentication/provider/login']);
  }

}
