import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { tap, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ToastrService } from "ngx-toastr";

import { AuthenticationService } from "src/app/core/authentication/authentication.service";

import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Register } from "src/app/shared";
import { OccupancyApplication } from "../../models/users";
import { data } from "jquery";

@Injectable({
  providedIn: "root",
})
export class ProvidersService {
  public BASE64_MARKER = ";base64,";
  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toaster: ToastrService
  ) {}

  getSpecialities() {
    const href = environment["getSpecialities"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  searchProfessionals(search): Observable<any> {
    const href = environment["searchProfessionals"];
    return this.http.get<any>(href, { params: search }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  specialitiesWithTitle() {
    const href = environment["specialitiesWithTitle"];

    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  //provider list page
  providerLists(body) {
    const href = environment["providerList"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getProfessionalTitleLists(isDoctor) {
    const href = environment["professionalListTitle"];
    return this.http.get<any>(href, { params: isDoctor }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getspecialityLists(query?) {
    const href = query
      ? environment["doctorSpecialty"] + "?provider=" + query
      : environment["doctorSpecialty"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getDegreeLists() {
    const href = environment["getDegreeList"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getLanguageLists() {
    const href = environment["languages"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getLicenceStates() {
    const href = environment["getLicenceStates"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  //end of provider list page
  //two way provider list
  twoWayProviderLists(id) {
    const href = environment["twoWayProvider"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  //get states api
  getStates() {
    const href = environment["getStates"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  popularProvider() {
    const href = environment["popularProvider"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  AllTopSpecialityProvider(id) {
    const href = environment["AllTopSpecialityProvider"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  setPaymentIntent(query?): Observable<any> {
    const href = query
      ? environment["setPaymentIntent"] + "?provider=" + query
      : environment["setPaymentIntent"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addDebitCard(body) {
    const href = environment["addDebitCard"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          // this.router.navigate(['/verify-otp']);
          return data;
        }
      })
    );
  }

  //provider list page
  resetPassword(body) {
    const href = environment["resetPassword"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          // this.router.navigate(['/verify-otp']);
          return data;
        }
      })
    );
  }
  createStripeSubscription(body) {
    const href = environment["createStripeSubscription"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }
  updateStripeSubscription(body) {
    const href = environment["updateStripeSubscription"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }
  docterJoinCall(body) {
    const href = environment["docterJoinCall"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  docterAppointmentSetting(body) {
    const href = environment["docterAppointmentSetting"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }
  docterAppointmentType(body) {
    const href = environment["addDoctorSchedule"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  editDoctorSchedule(body, provider?) {
    if (provider) {
      const headers = new HttpHeaders().set("x-temp-user", provider);
      const requestOptions = {
        headers: headers,
      };
      const href = environment["editDoctorSchedule"];
      return this.http.post<any>(href, body, requestOptions).pipe(
        map((data: any) => {
          if (data.status === "success") {
            return data;
          }
        })
      );
    } else {
      const href = environment["editDoctorSchedule"];
      return this.http.post<any>(href, body).pipe(
        map((data: any) => {
          if (data.status === "success") {
            return data;
          }
        })
      );
    }
  }

  verifyOtp(body) {
    const href = environment["resetPassword"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/verify-otp']);
          return data;
        }
      })
    );
  }

  signUp(body) {
    const href = environment["signUp"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data;
        }
      })
    );
  }
  resendOtp(body) {
    const href = environment["resendOtp"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data;
        }
      })
    );
  }
  startOfficeTreatment(appointmentId, body) {
    const href = environment["startOfficeTreatment"] + "/" + appointmentId;
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data;
        }
      })
    );
  }
  providerOnsiteAppointmentTracking(appointmentId, body) {
    const href =
      environment["providerOnsiteAppointmentTracking"] + "/" + appointmentId;
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data;
        }
      })
    );
  }

  checkEmailPhone(body) {
    const href = environment["checkEmailPhone"];
    return this.http.get<any>(href, { params: body }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data;
        }
      })
    );
  }

  providerSignUp(formBody) {
    const href = environment["signUp"];
    return this.http.post<any>(href, formBody).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data;
        }
      })
    );
  }

  providerUpdate(formBody) {
    const href = environment["updateProviderDetails"];
    return this.http.post<any>(href, formBody).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data;
        }
      })
    );
  }

  addProfessionalDetails(body) {
    const href = environment["updateProvider"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data.response;
        }
      })
    );
  }

  addLegalDocuments(formBody) {
    const fd = new FormData();

    fd.append("driverLicenseFront", formBody.driverLicenseFront);
    fd.append("driverLicenseBack", formBody.driverLicenseBack);
    fd.append("malPracticeInsuranceFront", formBody.malPracticeInsuranceFront);
    const href = environment["addLicenseDetails"];
    return this.http.post<any>(href, fd).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  addLegalDocumentsOnebyOne(formBody) {
    const href = environment["addLicenseDetails"];
    return this.http.post<any>(href, formBody).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  addLicenseDetails(formBody) {
    const fd = new FormData();
    const href = environment["addLicenseDetails"];
    return this.http.post<any>(href, formBody).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addLicenseOneByOne(formData) {
    const href = environment["addLicenseOneByOne"];
    return this.http.post<any>(href, formData).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  addNewLicenseIndividual(formBody) {
    const fd = new FormData();
    const href = environment["addNewLicenseIndividual"];
    return this.http.post<any>(href, formBody).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addDocumentDetails(formData) {
    const href = environment["addDocumentDetails"];
    return this.http.post<any>(href, formData).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  saveDocuments(body) {
    const href = environment["saveDocuments"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data.response;
        }
      })
    );
  }

  deleteProvidercard(id) {
    let href = environment["deleteProvidercard"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  addProviderDefaultAccount(body) {
    let href = environment["addProviderDefaultAccount"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  deleteProviderBankAccount(id) {
    let href = environment["deleteProviderBankAccount"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getPrimaryCard() {
    let href = environment["setPrimaryCard"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  setPrimaryCard(id) {
    let href = environment["setPrimaryCard"];
    return this.http.post<any>(href, id).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getServicesFromSpeciality(body) {
    const href = environment["getServicesFromSpeciality"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          // this.router.navigate(['/login']);
          return data.response;
        }
      })
    );
  }

  saveServicesSubservices(body) {
    const href = environment["saveServicesSubservices"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  apiProviderRegister(body) {
    const href = environment["apiProviderRegister"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  /**EDIT EXISTING DOCTOR**/
  apiProviderEdit(body) {
    const href = environment["editExistingDoctor"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  providerCancelAppointment(body) {
    const href = environment["providerCancelAppointment"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  completeOfficeTreatment(body): Observable<any> {
    const href = environment["completeOfficeTreatment"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  // Doctor Details
  docterDetails(id) {
    const href = environment["docterDetails"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDashboardAppointments(id) {
    const href = environment["providerDashboardAppointments"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  providerDashboardEarnings(id) {
    const href = environment["providerDashboardEarnings"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDashboardAppointmentDetails(id) {
    const href = environment["providerDashboardAppointmentDetails"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          console.log(data, "response");
          return data.response;
        }
      })
    );
  }

  unreadProviderNotification() {
    const href = environment["unreadProviderNotification"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  readProviderNoitification() {
    const href = environment["readProviderNoitification"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDashboardManageAppointments(data) {
    const href = environment["providerDashboardManageAppointments"];
    return this.http.get<any>(href, { params: data }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getAllInsuranceCompany() {
    const href = environment["getAllInsuranceCompany"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getProviderPatients() {
    const href = environment["getProviderPatients"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getProviderPatientDetails(id) {
    const href = environment["getProviderPatientDetails"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDashboardAppointmentRequests(id) {
    const href = environment["providerDashboardAppointmentRequests"];
    return this.http.get<any>(href, { params: id }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDashboardReviews() {
    const href = environment["providerDashboardReviews"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getProviderCompliments() {
    const href = environment["getProviderCompliments"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDashboardPatients() {
    const href = environment["providerDashboardPatients"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDashboardAppointmentCount() {
    const href = environment["providerDashboardAppointmentCount"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getDocterSchedules(timezone) {
    const href = environment["getDocterSchedules"];
    return this.http.get<any>(href, { params: timezone }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  providerDetails(query?: string) {
    const href = query
      ? environment["providerDetails"] + "?provider=" + query
      : environment["providerDetails"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  profileDetails() {
    const href = environment["profileDetail"];
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getProfessionalTitle() {
    const href = environment["getProfessionalTitle"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getStripePlan() {
    const href = environment["getStripePlan"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getProvidersCard(query?): Observable<any> {
    const href = query
      ? environment["getProviderscardList"] + "?provider=" + query
      : environment["getProviderscardList"];
    // const href = environment["getProviderscardList"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  deleteSchedules(body) {
    const href = environment["deleteSchedules"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }
  providerCheckAppointments(body) {
    const href = environment["providerCheckAppointments"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  providerCancelBulkAppointment(body) {
    const href = environment["providerCancelBulkAppointment"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  checkProviderOnDemandAppointments() {
    const href = environment["checkProviderOnDemandAppointments"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status === "success") {
          return data;
        }
      })
    );
  }

  providerAcceptAndRejectAppointment(body) {
    const href = environment["providerAcceptAndRejectAppointment"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          // this.router.navigate(['/verify-otp']);
          return data;
        }
      })
    );
  }

  addExternalAch(body) {
    const href = environment["addExternalAch"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status === "success") {
          // this.router.navigate(['/verify-otp']);
          return data;
        }
      })
    );
  }

  /**Shared Function to convert base64image to blob */
  convertBase64ToBlob(image, fileType) {
    let convertedFile = this.convertDataURIToBinary(image);
    let blob = new File(
      [convertedFile],
      `medical-image.${fileType.split("/")[1]}`,
      { type: fileType }
    );
    return blob;
  }

  convertDataURIToBinary(dataURI) {
    var base64Index =
      dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  removeProviderLicense(licenceId) {
    const href = environment["removeProviderLicense"];
    return this.http.get<any>(href, { params: licenceId }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getPatientRatingData() {
    const href = environment["getPatientRatingData"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  postPatientRatingData(body) {
    const href = environment["postPatientRatingData"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getProviderActivity() {
    const href = environment["providerActivity"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  makeProviderOffline() {
    const href = environment["makeProviderOffline"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  makeProviderOnline(body) {
    const href = environment["makeProviderOnline"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  trackProviderOnlineOffline(timeZone) {
    const href = environment["trackProviderOnlineOffline"];
    return this.http.get<any>(href, { params: timeZone }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  chekProviderRegistrationStatus() {
    const href = environment["chekProviderRegistrationStatus"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  postProviderRegistrationStatus(body) {
    const href = environment["chekProviderRegistrationStatus"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  changeProviderPassword(body) {
    const href = environment["changeProviderPassword"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  addProviderBankAccount(body) {
    const href = environment["addProviderBankAccount"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  providerRejoinCall(body) {
    const href = environment["providerRejoinCall"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getProviderBankAccounts() {
    const href = environment["getProviderBankAccounts"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  getProviderBusinessAddress() {
    const href = environment["providerBusinessAddres"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  addAndUpdateBusinessLocation(body) {
    const href = environment["providerBusinessAddres"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  deleteProviderBusinessAddress(body) {
    const href = environment["deleteProviderBusinessAddress"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  checkAddedSchedulesAndBanks() {
    const href = environment["checkAddedSchedulesAndBanks"];
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getProviderPayoutStatement(params) {
    const href = environment["getProviderPayoutStatement"];
    return this.http.get<any>(href, { params: params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  sendStripeStatementToEmail(params) {
    const href = environment["sendStripeStatementToEmail"];
    return this.http.get<any>(href, { params: params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  verifyEmailOtp(body) {
    const href = environment["verifyEmailOtp"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  resendEmailOtp(body) {
    const href = environment["resendEmailOtp"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  validatePromoCode(body) {
    const href = environment["validatePromoCode"];
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  getPreferencesList(type: number) {
    const href = environment["getAllPreferences"];
    const storage = localStorage.getItem("preferences");
    const preferences = JSON.parse(storage);
    console.log(preferences, "preferences");
    if (preferences) {
      return of(type ? this.filterPreference(type, preferences) : preferences);
    }
    return this.http.get<any>(href).pipe(
      map((data: any) => {
        console.log(data.response, "data.responsepref");
        if (data.status == "success") {
          localStorage.setItem("preferences", JSON.stringify(data.response));
          return this.filterPreference(type, data.response);
        }
      })
    );
  }

  private filterPreference(type: number, preference: []) {
    return preference.filter((value) => value["type"] === type);
  }

  savePreferences(body) {
    const href = environment["savePreferences"];
    const user = this.authenticationService.getUserInfo(true);
    return this.http.post<any>(href, body).pipe(
      map((data: any) => {
        if (data.status == "success") {
          this.authenticationService.setUser({ ...user, ...data.response });
          return data;
        }
      })
    );
  }
  searchMedication(data: any): Observable<any> {
    const href = environment["searchMedications"];
    return this.http.get<any>(href, { params: data }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  addMedication(data: any): Observable<any> {
    const href = environment["addMedication"];
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  searchLocation(data: any): Observable<any> {
    const href = environment["searchLocation"];
    return this.http.get<any>(href, { params: data }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getSavedEMR(id: string, paramsData: any): Observable<any> {
    const href = environment["getSavedDoctorEMR"] + `/${id}`;
    return this.http.get<any>(href, { params: paramsData }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          console.log(data, "response");
          return data.response;
        }
      })
    );
  }

  saveEMR(data: any): Observable<any> {
    const href = environment["saveDoctorEMR"];
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  searchUserForAppointment(str) {
    const href = environment["searchUser"];
    return this.http.get<any>(href, { params: { search: str } }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  uploadFiles(data) {
    const href = environment["uploadFiles"];
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getAllReferredPatients(page) {
    const href = environment["getAllReferredPatients"];
    return this.http.get<any>(`${href}?page=${page}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }
  getSingleReferredPatients(patientId) {
    const href = environment["getSingleReferredPatients"];
    return this.http.get<any>(`${href}/${patientId}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data;
        }
      })
    );
  }

  acceptOrDenyReferral(data) {
    const href = environment["acceptORDenyReferral"];
    return this.http.post<any>(href, data).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getSchedules(params) {
    const href = environment["getSchedules"];
    return this.http
      .get<any>(href, {
        params: {
          fromDate: params.fromDate,
          toDate: params.toDate,
          fromTime: params.fromTime,
          toTime: params.toTime,
        },
      })
      .pipe(
        map((data: any) => {
          if (data.status == "success") {
            return data.response;
          }
        })
      );
  }

  applyLeave(payload, timeZone) {
    const href = environment["applyLeaves"];
    return this.http.post<any>(href, payload, { params: { timeZone } }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getAppliedLeaves(timeZone) {
    const href = environment["appliedLeaves"];
    return this.http.get<any>(href, { params: { timeZone } }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  getOrgRequests(params) {
    const href = environment["getOrgRequests"];
    return this.http.get<any>(href, { params }).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  getOrgRequestsDetails(requestId) {
    const href = environment["getOrgRequestsDetails"];
    return this.http.get<any>(`${href}/${requestId}`).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }

  acceptOrReject(payload) {
    const href = environment["acceptOrRejectReq"];
    return this.http.post<any>(href, payload).pipe(
      map((data: any) => {
        if (data.status == "success") {
          return data.response;
        }
      })
    );
  }
  //
  // getGoogleMapPharmacy(text: string) {
  //   const url = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${text}
  //   &type=pharmacy&region=us&key=AIzaSyB0oMSYks73MwXluaDyBXqG9u_SCaCFs-I`;
  //   const postData = {
  //     method: "get",
  //     url: url,
  //   };

  //   const href = environment["proxy"];
  //   return this.http.post(href, postData).pipe(
  //     map((data: any) => {
  //       if (data.status === "OK") {
  //         return data.results;
  //       }
  //       return data;
  //     })
  //   );
  // }
}
