<!--------------------------------start of header------------------------------------------------>
<!-- header part start -->
<header [ngClass]="routeUrl === '/' ? '' : 'bg-change'">
  <div class="container header-width">
    <nav class="navbar navbar-expand-sm">
      <a
        id="logo-image"
        class="navbar-brand"
        [routerLink]="['/organization/dashboard']"
      >
        <img src="assets/images/new_logo.png" alt="" class="img-fluid" />
      </a>

      <form class="form-inline header_btn shape_dropdown ml-auto">
        <div></div>
        <!--  *ngIf="registrationComplete" -->
        <p
          class="iconly_bold cursor mr-1"
          [routerLink]="['/organization/dashboard']"
        >
          <!-- <img src="assets/images/Iconly-Bold-Notification.svg" alt="" />
          <span class="belliconNumber"
            ><i class="fa fa-circle" aria-hidden="true"></i>
            <small>1</small>
          </span> -->
        </p>

        <!-- <ng-template> -->
        <h3 class="loaction_txt position-relative">
          <div class="dflex_header">
            <div
              class="header_bgImg"
              [style.backgroundImage]="'url(assets/images/user.png);'"
            ></div>

            <span id="customdropdown">
              {{ userName }}<img src="assets/images/arrow.svg" alt="" />
            </span>
          </div>
          <span id="providerReadyModal"> </span>
          <span id="providerJoinModal"> </span>
          <div class="dropdown-menu newmenu-dropdown">
            <a
              class="dropdown-item d-flex align-items-center justify-content-between"
              style="cursor: pointer"
            >
              <span *ngIf="true"> Online </span>
              <span *ngIf="false"> Offline </span>
              <label class="switch-online">
                <input id="checkValue" type="checkbox" />
                <span class="slider-online round-online"></span>
              </label>
            </a>
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              style="cursor: pointer"
              [routerLink]="['/organization/dashboard']"
              >Dashboard</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" style="cursor: pointer" (click)="logout()"
              >Logout</a
            >
          </div>
        </h3>
        <!-- </ng-template> -->
      </form>
    </nav>
  </div>
</header>
