import { urls } from "./url";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// 4357107329
export const environment = {
  production: false,

  // host: 'http://localhost:3000',
  // host: 'https://production.hutano.com',
  // host: 'https://staging.hutano.com',
   host: "https://dev.hutano.com",
  // host: 'https://hutano.appening.xyz',
  ...urls,
  agora: {
    // appId: '73c50ac2404b41b1b45c3f77bbf52d5d'
    appId: "c7630b5181e74c63b20d6584988781b4", // Testing
    // appId: 'db91b3e9a6eb40f7b5370cfb6daeb3e6' // Live
  },

  stripeKey: "pk_test_LlxS6SLz0PrOm9IY9mxM0LHo006tjnSqWX",

  //   firebase: {
  //     apiKey: "AIzaSyDZ8l7TDi83na1Xiv16AiTioblpz1IbfGw",
  //     authDomain: "hutano-737fd.firebaseapp.com",
  //     projectId: "hutano-737fd",
  //     storageBucket: "hutano-737fd.appspot.com",
  //     messagingSenderId: "926880958710",
  //     appId: "1:926880958710:web:fcf0fc36bc4ae8c3f7c4d7",
  //     measurementId: "G-8R2MN55N5Y"
  //   }
  // };

  firebase: {
    apiKey: "AIzaSyC8Foj0EQFzLwUUHg2vWyD0-biDfb3Swpg",
    authDomain: "hutano-269905.firebaseapp.com",
    databaseURL: "https://hutano-269905.firebaseio.com",
    projectId: "hutano-269905",
    storageBucket: "hutano-269905.appspot.com",
    messagingSenderId: "135216344614",
    appId: "1:135216344614:web:4d9449f73c830d0c7f80e7",
    measurementId: "G-6T5DB9ZV8D",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
