/** MODUELS */
import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
/** END MODUELS */

/** COMPONENTS */
import { AdminComponent } from "./layouts/admin/admin.component";
import { LayoutComponent } from "./layouts/users/layout/layout.component";
import { ProviderLayoutComponent } from "./layouts/providers/layout/layout.component";
import { HeaderComponent } from "./layouts/users/header/header.component";
import { FooterComponent } from "./layouts/users/footer/footer.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { AuthHeaderComponent } from "./layouts/auth-layout/auth-header/auth-header.component";
import { AuthFooterComponent } from "./layouts/auth-layout/auth-footer/auth-footer.component";
import { GoogleAutoComplete } from "./directives/google-autocomplete";
import { ProviderHeaderComponent } from "./layouts/providers/header/header.component";
import { AlphabetOnlyDirective } from "./directives/alphabet-only.directive";
import { RouterModule } from "@angular/router";
import { InvalidControlScrollDirective } from "./directives/ng-invalid-control-scroll-wt-directive";
import { NoLeadingSpaceDirective } from "./directives/no-leading-space.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { SearchDirective } from "./directives/search.directive";
import { AlphaNumericDirective } from "./directives/alpha-numeric.directive";
import { CorporateHeaderComponent } from "./layouts/corporate/header/header.component";
import { CorporateLayoutComponent } from "./layouts/corporate/corporate-layout/corporate-layout.component";
import { OrganizationLayoutComponent } from "./layouts/organization/organization-layout/organization-layout.component";
import { OrganizationHeaderComponent } from "./layouts/organization/organization-header/organization-header.component";

/** End COMPONENTS */

const COMPONENTS = [
  AuthLayoutComponent,
  LayoutComponent,
  ProviderLayoutComponent,
  HeaderComponent,
  ProviderHeaderComponent,
  FooterComponent,
  AdminComponent,
  CorporateHeaderComponent,
  OrganizationHeaderComponent,
];

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
];

const DIRECTIVE = [
  GoogleAutoComplete,
  AlphabetOnlyDirective,
  InvalidControlScrollDirective,
  NoLeadingSpaceDirective,
  OnlyNumbersDirective,
  SearchDirective,
  AlphaNumericDirective,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    AuthHeaderComponent,
    AuthFooterComponent,
    HeaderComponent,
    ProviderHeaderComponent,
    FooterComponent,
    ...DIRECTIVE,
    CorporateLayoutComponent,
    OrganizationLayoutComponent,
  ],
  imports: [...BASE_MODULES],
  exports: [...BASE_MODULES, ...COMPONENTS, ...DIRECTIVE],
  providers: [LayoutsModule],
})
export class LayoutsModule {
  // static forRoot(): ModuleWithProviders {
  //   return <ModuleWithProviders>{
  //     ngModule: LayoutsModule,
  //   };
  // }
}
