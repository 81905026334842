
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SearchPipe'
})
export class SearchDirective implements PipeTransform {
  transform(items: Array<any>, attribute: string, term: string): Array<any> {
    // debugger
    if (!term || term === '') return items;

    return items.filter(item => {
      if (item[attribute].toLowerCase().startsWith(term.toLowerCase())) return item;
    });
  }
}
