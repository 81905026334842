<!-- footer part Start -->
<footer class="footer_pt" style="background: #fff !important;">
    <div class="container footer_width">
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                <div class="footer_link">
                    <ul>

                        <li><a [routerLink]="['/about-us']">About</a></li>
                        <li><a [routerLink]="['/why-hutano']">Why Hutano </a></li>
                        <li><a [routerLink]="['/contact-us']">Contact</a></li>
                        <!-- <li><a [routerLink]="['/blog']">Blog</a></li> -->
                        <!-- <li><a [routerLink]="['/blog']">Careers</a></li> -->
                        <!-- <li><a [routerLink]="['/blog']">Press</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3  col-4">
                <div class="footer_link">
                    <ul>
                        <li><a href="#">Search for providers</a></li>
                        <li><a [routerLink]="['/features-and-functionality']">Features and Functionality</a></li>
                        <li><a [routerLink]="['/investors']">Investor Relationships</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3  col-4">
                <div class="footer_link">
                    <ul>
                        <li><a [routerLink]="['/value-of-hutano']">Value of Hutano</a></li>
                        <li><a [routerLink]="['/provider-benefits']">Provider Benefits </a></li>
                        <li><a [routerLink]="['/how-hutano-works']">How Hutano works</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3  col-sm-3  col-4 link-pb">
                <div class="footer_link">
                    <ul>
                        <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                        <li><a [routerLink]="['/terms-and-conditions']">Terms & Conditions</a></li>
                        <li><a [routerLink]="['/hipaa-compliance']">HIPAA Compliance </a></li>
                    </ul>
                </div>
            </div>


        </div>
        <div class="divider_footer">
            <div class="row">
                <div class="col-xl-12">
                    <div class="footer_logo pb-0">
                        <h1><img style="max-width: 200px;" src="assets/images/new_logo.png" alt=""></h1>
                        <p>Copyright © 2022,Hutano Technologies, Inc. DBA Hutano All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<script>
    $(document).ready(function() {
        $('.topSpeciality').owlCarousel({
            loop: true,
            margin: 24,
            dots: false,
            responsiveClass: true,
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                414: {
                    items: 1,
                    nav: true,
                    loop: false,
                    margin: 0,
                },
                767: {
                    items: 2,
                    nav: true
                },
                1024: {
                    items: 3,
                    nav: true,
                    loop: false
                }
            }
        })

        $('.medical_slider').owlCarousel({
            loop: true,
            dots: false,
            responsiveClass: true,
            navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
            responsive: {
                320: {
                    items: 3,
                    nav: true
                },
                360: {
                    items: 3,
                    nav: true,
                    loop: false,
                    margin: 25,
                },
                375: {
                    items: 3,
                    nav: true,
                    loop: false,
                    margin: 25,
                },
                414: {
                    items: 4,
                    nav: true,
                    loop: false,
                    margin: 0,
                },
                767: {
                    items: 6,
                    nav: true,
                    loop: false,
                    margin: 30,
                },
                991: {
                    items: 7,
                    nav: true,
                    margin: 20,
                    loop: false
                },
                1024: {
                    items: 7,
                    nav: true,
                    margin: 10,
                    loop: false
                },
                1366: {
                    items: 8,
                    nav: true,
                    margin: 20,
                    loop: false
                }
            }
        })

    });
</script>
<!-- footer part End -->