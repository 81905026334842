<!--------------------------------start of header------------------------------------------------>
<!-- header part start -->

<header [ngClass]="routeUrl === '/' ? '' : 'bg-change'">
  <div class="container-fluid">
    <nav class="navbar">
      <a id="logo-image" class="navbar-brand" (click)="goToHome()">
        <img src="assets/images/new_logo.png" alt="" class="img-fluid" />
      </a>
      <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span id="toggle_bar"> <i class="fa fa-bars" aria-hidden="true"></i></span>
      </button> -->

      <div class="right ml-auto">
        <form class="form-inline header_btn shape_dropdown align-items-end">
          <span
            id="readyModal"
            data-toggle="modal"
            data-target="#exampleModal50"
          ></span>
          <span
            id="joinModal"
            data-toggle="modal"
            data-target="#exampleModal60"
          ></span>
          <p
            *ngIf="isLogin"
            (click)="goToNotification()"
            class="iconly_bold cursor"
          >
            <img src="assets/images/Iconly-Bold-Notification.svg" alt="" />
            <span
              class="belliconNumber"
              *ngIf="notificationCount?.count && !showPaymentRedDot"
            >
              <i class="fa fa-circle" aria-hidden="true"></i>
              <small>{{ notificationCount?.count }}</small>
            </span>
            <span class="belliconNumber" *ngIf="showPaymentRedDot">
              <i class="fa fa-circle" aria-hidden="true"></i> <small></small>
            </span>
          </p>

          <h3 class="loaction_txt address-sidecontent align-items-start">
            <img
              src="assets/images/location.svg"
              alt=""
              class="img-fluid"
              style="width: 2.5rem"
            />
            <span
              class="d-flex mr-3"
              id="las-vegash"
              data-toggle="modal"
              data-target="#exampleModal8"
            >
              <p class="addres-Setwidth">{{ address?.address }}</p>
              &nbsp; &nbsp; . &nbsp;&nbsp;<strong class="w-mil"
                >Within {{ address?.radius || 100 }} miles
                <i class="fa fa-angle-down" aria-hidden="true"></i
              ></strong>
            </span>
          </h3>

          <div class="headerBaricon-img dropdown">
            <button
              class="border-0"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="assets/images/headerBar.svg"
                alt=""
                class="mr-4"
                style="width: 2.6rem"
              />
              <span
                id="img_ptHeader"
                [style.backgroundImage]="
                  userImage
                    ? 'url(' + environment.imageBaseUrl + userImage + ')'
                    : 'url(assets/images/user_img.svg)'
                "
              ></span>
            </button>

            <div
              class="dropdown-menu mt-0"
              aria-labelledby="dropdownMenuButton"
            >
              <div *ngIf="!isAuthenticated">
                <a
                  class="dropdown-item"
                  (click)="loginSignUp('/authentication/user/verify-mobile')"
                  >Sign up</a
                >
                <a
                  class="dropdown-item"
                  (click)="loginSignUp('/authentication/user/login')"
                  >Log in</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  (click)="loginSignUp('/authentication/provider/login')"
                  >Provider Login</a
                >
                <a
                  class="dropdown-item"
                  (click)="loginSignUp('/authentication/provider/sign-up')"
                  >Provider Sign Up</a
                >
                <!-- <a
                  class="dropdown-item"
                  (click)="loginSignUp('/authentication/admin-login')"
                  >Admin Login</a
                > -->
                <a class="dropdown-item" href="#">Help</a>
              </div>

              <div *ngIf="isAuthenticated">
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  (click)="goToProfile('dashboard')"
                  >My Dashboard</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  (click)="goToProfile()"
                  >My Account</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  (click)="goToProfile('/appointment-list')"
                  >Appointments</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  (click)="goToProfile('/request-list')"
                  >My Request</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="dropdown-item"
                  style="cursor: pointer"
                  (click)="logout()"
                  >Logout</a
                >
              </div>
            </div>
          </div>
        </form>
      </div>
    </nav>
  </div>
</header>

<!-- header part End -->
<!--End of header-->

<div
  class="modal fade uploadImg send-req_popup"
  data-backdrop="false"
  data-keyboard="false"
  tabindex="-1"
  id="exampleModal60"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          type="button"
          class="close-pop"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeRejoinModal()"
        >
          <img
            src="assets/images/close_gray.svg"
            alt=""
            style="width: 3rem"
            class="cursor"
          />
        </button>
      </div>
      <div class="popupImg_select">
        <div class="pay_complete">
          <h1>Rejoin call</h1>
          <p>{{ rejoinMessage }}</p>
          <div class="end_tretmentBtn">
            <button
              id="tretOneBtn"
              data-dismiss="modal"
              (click)="closeRejoinModal()"
            >
              Cancel
            </button>
            <button id="tretTwoBtn" data-dismiss="modal" (click)="rejoinCall()">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade uploadImg send-req_popup"
  data-backdrop="false"
  data-keyboard="false"
  tabindex="-1"
  id="exampleModal50"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header border-0">
        <button
          type="button"
          class="close-pop"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeRejoinModal()"
        >
          <img
            src="assets/images/close_gray.svg"
            alt=""
            style="width: 3rem"
            class="cursor"
          />
        </button>
      </div>
      <div class="popupImg_select">
        <div class="pay_complete">
          <h1>The Provider Is Ready</h1>
          <p class="text-center">{{ providerReady }}</p>
          <div class="end_tretmentBtn">
            <button id="tretOneBtn" data-dismiss="modal">Cancel</button>
            <button
              id="tretTwoBtn"
              data-dismiss="modal"
              (click)="goToAppointmentList()"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
