import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { appToaster } from "src/app/configs";
import { AuthenticationService } from "src/app/core/authentication/authentication.service";
import { UsersService } from "src/app/core/services";
import { CorporateService } from "src/app/core/services/corporate/corporate.service";
import { MessagingService } from "src/app/core/services/messaging.service";
import { ProvidersService } from "src/app/core/services/providers";
import { UsersExtendService } from "src/app/core/services/users/users-extend.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "corporate-app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class CorporateHeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private corporateService: CorporateService,
    public authenticationService: AuthenticationService
  ) {}
  public routeUrl;
  public userName: any;
  public notifications = 0;
  ngOnInit() {
    const userDetails = JSON.parse(localStorage.getItem("corporateUser"));
    this.userName = userDetails["title"] + "." + userDetails["firstName"];

    this.corporateService.notReadNotifications().subscribe((res) => {
      this.notifications = res.response.total;
    });
  }
  ngDoCheck() {}

  goToLogin() {
    this.router.navigate(["authentication/provider/login"]);
  }

  logout() {
    this.authenticationService.corporateLogout();
    this.router.navigate(["authentication/corporate/login"]);
    sessionStorage.clear();
  }
}
