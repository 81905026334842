import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import "firebase/analytics";
import "firebase/firestore";

@Injectable()
export class AnalyticsSharedService {
private analytics: any;
    constructor() {
        // use any one of the codes below depends on the version
       // firebase version 8
        this.analytics = firebase.default.analytics();
        // firebase version 7
        // this.analytics = firebase.analytics();
    }
logEvents(eventName: String): void {
        // shared method to log the events
        this.analytics.logEvent(eventName);
    }
}
