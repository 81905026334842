import { Observable } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ImageTransform, ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css']
})
export class ImageUploaderComponent implements OnInit {
  @Input() title: string = 'File';
  @Input() modalId: string;
  @Input() stopClosingModalObservable: Observable<any>;
  @Output() imageBase64 = new EventEmitter<{
    ext: string,
    base64: string
  }>();
  @Output() imageChangedListener = new EventEmitter<boolean>(false);

  public isImageChangedEvent = '';
  public croppedImage = '';
  public fileExtension: string;
  public stopClosingModal = false;

  public canvasRotation = 0
  public cropperImageTransform: ImageTransform = {};
  public scale = 1;
  public sliderConfig = {
    floor: 1,
    ceil: 3,
    step: 0.1,
  }
  public straightScale = 0;
  public straightConfig = {
    floor: -45,
    ceil: 45,
    step: 0.1,
    showTicks: true
  }

  constructor(
    private TS: ToastrService,
  ) { }
  ngOnInit(): void {

    if(this.stopClosingModalObservable){
      this.stopClosingModalObservable.subscribe(data => {
        if(!data){
          this.isImageChangedEvent = null
        }
        this.stopClosingModal = data
      });
    }
  }

  zoom(event) {
    this.scale = event;
    this.cropperImageTransform = {
      ...this.cropperImageTransform,
      scale: this.scale
    };
  }

  zoomOut() {
    this.scale -= .1;
    this.cropperImageTransform = {
      ...this.cropperImageTransform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.cropperImageTransform = {
      ...this.cropperImageTransform,
      scale: this.scale
    };
  }

  rotateImage(degree) {
    this.straightScale = degree;
    this.canvasRotation = this.canvasRotation + degree;
  }
  imageChangedEvent(event) {
    const [file] = event.target.files;
    if (file.size > 10971520) {
      this.TS.error("File size is larger than 10MB, Please choose smaller file.")
      return false;
    }
    this.isImageChangedEvent = event;
  }
  fileChangeEvent(event: any): void {
    this.isImageChangedEvent = event;
    this.imageChangedListener.emit(event)
    if (event.target.files.length > 0) {
      this.fileExtension = event.target.files[0].type
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    // if (e.size > 5120000 * 5) {
    //   this.TS.error("File size larger than 25mb.");
    //   this.cropper.clean();
    //   return false;
    // }
    this.croppedImage = event.base64;
  }

  cropImage() {
    this.imageBase64.emit({
      base64: this.croppedImage,
      ext: this.fileExtension
    });
    if(!this.stopClosingModal){
      this.isImageChangedEvent = null
      this.imageChangedListener.emit(null)
    }
  }

  removeImage() {
    this.croppedImage = ''
    this.isImageChangedEvent = null;
    // this.imageBase64.emit({
    //   base64: '',
    //   ext: ''
    // });
    // this.isImageChangedEvent = null
    // this.imageChangedListener.emit(null)
  }
  // rotateLeft() {
  //   this.canvasRotation--;
  //   this.flipAfterRotate();
  // }
  // rotateRight() {
  //   this.canvasRotation++;
  //   this.flipAfterRotate();
  // }
  // private flipAfterRotate() {
  //   const flippedH = this.cropperImageTransform.flipH;
  //   const flippedV = this.cropperImageTransform.flipV;
  //   this.cropperImageTransform = {
  //     ...this.cropperImageTransform,
  //     flipH: flippedV,
  //     flipV: flippedH,
  //   };
  // }
}
